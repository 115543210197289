import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const initialValues = {
  fontname: "",
  price: "",
  file: null,
};

const validationSchema = Yup.object().shape({
  fontname: Yup.string().required("Font Name is required"),
  price: Yup.number()
    .typeError("Price must be a number")
    .positive("Price must be positive")
    .required("Price is required"),
  file: Yup.mixed().test(
    "type",
    "Only .ttf files are supported",
    (value) => value && value.name?.split(".")?.pop() === "ttf",
  ),
});

const EditFontModal = ({ closeModal }) => {
  return (
    <div className="modal fixed inset-0 flex items-center justify-center z-50">
      <div className="modal-content p-8 bg-white w-96 rounded shadow-lg">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <h1 className="text-lg font-bold   mb-2">Edit Font</h1>
              <div className="mb-4">
                <label htmlFor="fontname" className="block text-gray-700">
                  Font Name
                </label>
                <Field
                  type="text"
                  id="fontname"
                  name="fontname"
                  className="w-full px-3 py-2 border rounded border-gray-300"
                />
                <ErrorMessage
                  name="fontname"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="price" className="block text-gray-700">
                  Price
                </label>
                <Field
                  type="number"
                  id="price"
                  name="price"
                  className="w-full px-3 py-2 border rounded border-gray-300"
                />
                <ErrorMessage
                  name="price"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="mb-4">
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".ttf"
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  className="w-full px-3 py-2 border rounded border-gray-300 bg-blue-100 hover:bg-blue-200"
                />
                <ErrorMessage
                  name="file"
                  component="div"
                  className="text-red-600"
                />
                <p className="text-sm text-gray-600 mt-2">
                  Upload items fonts <br /> supported files (.ttf{" "}
                  <span className="text-red-700">*</span> )
                </p>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditFontModal;
