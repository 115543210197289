import { Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
export default function SingleSelect({
  selectedItem,
  items,
  onSelect,
  keyname,
  id_key,
  searchValue,
  handleSearch,
}) {
  const handleChange = (value) => {
    onSelect(value);
  };
  return (
    <Combobox value={selectedItem} onChange={handleChange}>
      <div className="relative">
        <Combobox.Button className="relative w-32 cursor-pointer overflow-hidden">
          <Combobox.Input
            className="form-select w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-black/30 pl-2.5 py-1.5 text-xs cursor-pointer"
            onChange={(event) => {
              handleSearch(event.target.value);
            }}
            displayValue={(person) =>
              person[keyname] ? person[keyname] : "all"
            }
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 opacity-0">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => handleSearch("")}
        >
          <Combobox.Options className="absolute mt-1 z-[999] max-h-60 w-full md:w-44 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-4 pr-4 ${
                  active ? "bg-[#186ced] text-white" : "text-gray-900"
                }`
              }
              value={{
                [id_key]: "",
                [keyname]: "All",
              }}
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={`block truncate capitalize ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    all
                  </span>
                  {selectedItem && selectedItem[id_key] === "" ? (
                    <span
                      className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                        active ? "text-white" : "text-teal-600"
                      }`}
                    >
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Combobox.Option>
            {items?.length === 0 && searchValue !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              items?.map((types, i) => (
                <Combobox.Option
                  key={i}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-4 pr-4 ${
                      active ? "bg-[#186ced] text-white" : "text-gray-900"
                    }`
                  }
                  value={types}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate capitalize ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {types && types[keyname]}
                      </span>
                      {selectedItem &&
                      selectedItem[id_key] === types[id_key] ? (
                        <span
                          className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                            active ? "text-white" : "text-teal-600"
                          }`}
                        >
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
