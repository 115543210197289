import React, { useEffect, useState,Fragment } from 'react'
import Template from '../../components/common/Template'
import { ReactComponent as SearchIcon } from "../../../src/assets/icons/searchicon.svg";
import AddAgent from './add_agent';
import EditAgent from './edit_agent';
import { useDispatch, useSelector } from 'react-redux';
import { imageOnError } from '../../components/utils/imageOnError';
import { getAllAgentList } from '../../services/api';
import { postPerPage } from '../../constants';
import { setAgentManagementCurrentPage, setDeactivatedAgentManagementCurrentPage } from '../../redux/slices/paginationSlice';
import Pagination from '../../components/common/pagination';
import DeleteAgent from './deleteagent';
import { Tab } from '@headlessui/react';
import ActiveAgent from './activeagent';
import moment from 'moment';



const AgentManagement = () => {
  const dispatch = useDispatch();
  const [searchValue, setsearchValue] = useState("")
  const [deactivatedAgentSearchValue, setdeactivatedAgentSearchValue] = useState("")
  const { totalagent,agentList} = useSelector((state) => state.agent);
  const [currentTab, setcurrentTab] = useState(0)
  const { agentManagementCurrentPage,deactivatedagentManagementCurrentPage} = useSelector((state) => state.pagination);

  useEffect(() => {
    if (currentTab === 0) { 
      dispatch(getAllAgentList(agentManagementCurrentPage,postPerPage,searchValue,1))
    } else {
      dispatch(getAllAgentList(deactivatedagentManagementCurrentPage,postPerPage,deactivatedAgentSearchValue,0))
    }
    //eslint-disable-next-line
  },[agentManagementCurrentPage,dispatch,deactivatedagentManagementCurrentPage,currentTab])

  const handleSearch = (e,index) => {
    if (index === 0) {
      setsearchValue(e.target.value);
      dispatch(getAllAgentList(1, postPerPage, e.target.value,1))
      dispatch(setAgentManagementCurrentPage(1));
    } else {
      setdeactivatedAgentSearchValue(e.target.value);
      dispatch(getAllAgentList(1, postPerPage, e.target.value,0))
      dispatch(setDeactivatedAgentManagementCurrentPage(1));
    }
  }

  return (
    <Template>
      {
        currentTab===0 ?   <div className='flex-row bg-slate-300 justify-between flex items-center'>
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
             Active Agent List
            </p>
          </div>
        </div>
        <div className='flex flex-row px-5 gap-4'>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
              <input
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
                placeholder="Enter agent name"
                value={searchValue}
                onChange={(e)=>handleSearch(e,0)}
                />
              <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
                <SearchIcon />
              </div>
            </div>
          </div>
          <AddAgent />
        </div>
      </div>:   <div className='flex-row bg-slate-300 justify-between flex items-center'>
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Inactive  Agent List
            </p>
          </div>
        </div>
        <div className='flex flex-row px-5 gap-4'>
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
              <input
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
                placeholder="Enter agent name"
                value={deactivatedAgentSearchValue}
                onChange={(e)=>handleSearch(e,1)}
              />
              <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
   }
      <Tab.Group selectedIndex={currentTab} onChange={setcurrentTab}>
      <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-8 border border-black/[0.08]">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Active
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Inactive
                  </button>
                )}
              </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
      {
      agentList?.length ?
        <table class="table-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-black/10 text-xs font-semibold py-3 text-left px-4">
              Name
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Email
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Location
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Address
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center w-0">
              Date
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {agentList?.map((agent) => (
            <tr
            className=" w-full group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5" 
          >
            <td className="text-sm py-4 px-4">
            <div className="flex justify-start items-center flex-grow gap-2">
                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-10 h-10 overflow-hidden rounded-full bg-[#eaecf4]">
                  <img
                     src={agent?.image?agent?.image:'/profile.png'}
                      alt="preview"   effect="blur"
                      onError={imageOnError}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-col justify-start items-start flex-grow relative">
                  <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                  {agent?.agent_name}
                  </p>
                </div>
              </div>
            </td>
            <td className="text-sm p-4">
             {agent?.email}
            </td>
            <td className="text-sm p-4">{agent?.location_name}</td>
            <td className="text-sm p-4">
              {agent?.address}
            </td>
            <td className="text-sm p-4 whitespace-nowrap" >
            {agent?.creation_date}
            </td>
            <td className="text-sm flex p-4 gap-2">
              <EditAgent agent_id={agent?.agent_id} value={ searchValue}  />
              <DeleteAgent  agent={agent} value={ searchValue} />
            </td>
          </tr>
          ))
          }
        </tbody>


        </table>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No agent found
              </span>
            </div>
        }

        <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={agentManagementCurrentPage}
              totalCount={totalagent}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setAgentManagementCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
          </Tab.Panel>
          <Tab.Panel>
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
      {
      agentList?.length ?
        <table class="table-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-black/10 text-xs font-semibold py-3 text-left px-4">
              Name
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Email
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Location
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Address
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center w-0">
              Date
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {agentList?.map((agent) => (
            <tr
            className=" w-full group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5" 
          >
            <td className="text-sm py-4 px-4">
            <div className="flex justify-start items-center flex-grow gap-4">
                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-10 h-10 overflow-hidden rounded-full bg-[#eaecf4]">
                  <img
                     src={agent?.image?agent?.image:'/profile.png'}
                      alt="preview"   effect="blur"
                      onError={imageOnError}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-col justify-start items-start flex-grow relative">
                  <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                  {agent?.agent_name}
                  </p>
                </div>
              </div>
            </td>
            <td className="text-sm p-4">
             {agent?.email}
            </td>
            <td className="text-sm p-4">{agent?.location_name}</td>
            <td className="text-sm p-4">
              {agent?.address}
            </td>
            <td className="text-sm p-4 whitespace-nowrap" >
            {agent?.creation_date}
            </td>
            <td className="text-sm flex p-4 gap-2">
                <ActiveAgent agent={agent} value={ deactivatedAgentSearchValue}  />
            </td>
          </tr>
          ))
          }
        </tbody>


        </table>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No agent found
              </span>
            </div>
        }

        <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={deactivatedagentManagementCurrentPage}
              totalCount={totalagent}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setDeactivatedAgentManagementCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Template>
  )
}

export default AgentManagement