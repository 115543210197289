export const DASHBOARD="/"
export const LOGIN="/login"
export const ANIMALLIST="/animal_list"
export const ADDANIMALLIST="/animal_list/add"
export const EDITANIMALLIST="/animal_list/edit/:animal_id"
export const AGENTLIST="/agent_list"
export const LOCATIONLIST="/location_list"
export const ADDLOCATION="/location/add"
export const EDITLOCATION="/location_edit/:location_id"
export const USERSLIST="/users_list"
export const USERDETAILS="/user/:user_id"
export const ENQUERYLIST="/enquires"
export const FONTSLIST="/fonts_list"