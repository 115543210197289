import React, { useState } from "react";
import Template from "../../components/common/Template";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/20/solid";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionmarkicon.svg";
import { Form, Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup"
import Files from "react-files"
import { toast } from "react-hot-toast";
import axiosConfig from "../../axios_config";
const AddAnimal = () => {
  const [animalImages, setanimalImages] = useState("")
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    desc:"",
    livespan: "",
    food:"",
    activity:"",
};
//Yup validator Schema
const EditInformationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(3, "Too short")
    .max(40, "Too Long")
        .required("Please enter  name"),
    desc:Yup.string().trim().required("required").min(20,"minimum 20 words").max(200,"maximum 200 words"),
    livespan: Yup.string().required().matches(/^[0-9]*(\.[0-9]{0,2})?$/,"enter valid life span"),
    food: Yup.string().required("required"),
    activity:Yup.string().required("required"),
});
const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async(values, action) => {
      if (animalImages) {
        try {
          setisLoading(true);
          var formdata = new FormData();
            formdata.append("image", animalImages);
          formdata.append("name", values.name);
          formdata.append("description", values.desc);
          formdata.append("life_span", values.livespan);
          formdata.append("food", values.food);
          formdata.append("activity",values.activity);
          formdata.append("is_active", "true");
          const { data } = await axiosConfig.post(`/animal/save`, formdata);
          if (data?.data?.status === "success") {
            toast.success(data?.data?.message || "Animal added successfully")
            navigate("/animal_list")
            setisLoading(false);
          } else {
            toast.error(data?.data?.message || "something went wrong" )
            setisLoading(false);
          }
        } catch (error) {
          toast.error("can't proceed your request right now!!")
          setisLoading(false);
        }
      } else {
        toast.error("Upload atleast one image of animal")
      }
    },
  });

  const handleChangeDesc = (e) => {
    const { value } = e.target;
    if (value.length > 200) {
      return;
    }
    handleChange(e)
  }

  const handleImageChange = (files) => {
    if (files.length) {
      setanimalImages(files[0])
    }
  }

  const handleError = (error) => {
    toast.error(error.message)
  }


  return (
    <Template>
    <div className="flex-row bg-slate-300 justify-between flex items-center">
      <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
        <Link to={"/animal_list"}>
            <ArrowLeftIcon className="h-7" />
          </Link>
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Add New Animal
          </p>
        </div>
      </div>

      <button className="flex  items-center gap-3 mr-9 flex-row">
        {/* <Link
                to={"/animal_list/add"}
                // className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 transition-all"
              > */}
        <button className=" flex  bg-[#6a9818] px-5 py-2 text-white rounded-3xl items-center justify-center" onClick={handleSubmit} disabled={isLoading ?"disabled":""}>
          {/* <PlusIcon className='h-5' /> */}
          <p>Save</p>
        </button>
        {/* </Link> */}

        {/* <AddAgent /> */}
      </button>
    </div>
    <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0  gap-12">
    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-16 px-8 pt-12 pb-20 bg-white">
        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2">
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-4 flex-wrap">
              <Files
                        className="files-dropzone cursor-pointer"
                        onChange={(file) => handleImageChange(file)}
                        onError={handleError}
                        accepts={["image/png", "image/jpeg", "image/jpg"]}
                        maxFileSize={20000000}
                        minFileSize={0}
                        clickable
                      >
                        {animalImages ? (
                          <img
                            src={animalImages?.preview?.url}
                            alt="animal_image"
                            className="h-[122px] w-[122px] object-cover"
                          />
                        ) : (
                          <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                            <UploadIcon />
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                              <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                                Upload
                              </p>
                            </div>
                          </div>
                        )}
                      </Files>
              </div>
              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <QuestionMarkIcon />
                <p className="flex-grow-0 flex-shrink-0 text-[11px] font-medium text-left capitalize text-[#7b8794]">
                Recommendations
                </p>
              </div>
            </div>
        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[768px] gap-12">
          {/* Basic Information */}
          <div
            id="basic_info"
            className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4"
          >
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
                Basic Information
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
              <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                Name
              </p>
              <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                <input
                  className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                  placeholder="Enter Animal Name"
                  type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    style={errors.name && touched.name ? {border:"1px solid red"}:{}}
                />
              </div>
              </div>
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
            <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  Description
                  </p>
                </div>
                <div className="flex flex-col flex-grow w-[608px]">
                  <textarea
                    type="text"
                    className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea] h-40 w-full"
                    placeholder="Type here"
                     value={values.desc}
                     onChange={handleChangeDesc}
                    onBlur={handleBlur}
                    name="desc"
                    style={
                       errors.desc && touched.desc
                         ? { border: "1px solid red" }
                         : {}
                     }
                  ></textarea>
                      <div className="flex justify-between items-center">
                                {
                                  (values.desc && values.desc?.length < 20) &&
                                  <span className="text-red-500">20 minimum</span>
                                }
                                <div className="flex">
                                  <span className="text-green-500">{values.desc?.length}</span>/<span className="text-red-500">200</span>
                                </div>
                              </div>
                </div>
                </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
              <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                Livespan
              </p>
              <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                <input
                  className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                  placeholder="Enter Animal Livespan In Years"
                  type="number"
                    name="livespan"
                    value={values.livespan}
                    onChange={handleChange}
                    style={errors.livespan && touched.livespan ? { border: "1px solid red" } : {}}
                    onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
              <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                Food
              </p>
              <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                <input
                  className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                  placeholder="Enter Food Name"
                  name="food"
                    value={values.food}
                    onChange={handleChange}
                    style={errors.food && touched.food ? { border: "1px solid red" } : {}}
                    onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
              <p className="flex-grow-0 flex-shrink-0 w-[150px] text-[13px] font-medium text-left text-black/80">
                Activity
              </p>
              <div className="flex flex-col justify-start items-start flex-grow gap-4 rounded-[5px]">
                <input
                  className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 px-4 py-3 rounded bg-white border border-[#dbe1ea]"
                  placeholder="Enter Animal Activites"
                  name="activity"
                  value={values.activity}
                  onChange={handleChange}
                  style={errors.activity && touched.activity ? { border: "1px solid red" } : {}}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Template>
  )
}

export default AddAnimal