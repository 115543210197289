import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        isLoading: true,
        noOfPostHistroicData: [],
    },
    reducers: {
        getNoOfPostHistroicDataRequest:(state)=>{
            state.isLoading = true;
        },
        getNoOfPostHistroicDataSuccess:(state,{payload})=>{
            state.isLoading = false;
            state.noOfPostHistroicData = payload;
        },
        getNoOfPostHistroicDataFail:(state)=>{
            state.isLoading = false;
        },
        getNoOfPhotosPiechartDataRequest:(state)=>{
            state.piechartDataLoading = true;
        },
        getNoOfPhotosPiechartDataSuccess:(state,{payload})=>{
            state.piechartDataLoading = false;
            //get 4 category data
            const sortedData = payload?.sort((a, b) => b.posts - a.posts);
            const topLabels = sortedData.slice(0, 3);
            const otherPosts = sortedData.slice(3).reduce((total, item) => total + item.posts, 0);
            const result = [...topLabels, { label: "Others", posts: otherPosts }];
            state.pieChartData = result;
        },
        getNoOfPhotosPiechartDataFail:(state)=>{
            state.piechartDataLoading = false;
        },
    }
})

export const {getNoOfPostHistroicDataFail,getNoOfPostHistroicDataRequest,getNoOfPostHistroicDataSuccess,getNoOfPhotosPiechartDataFail,getNoOfPhotosPiechartDataRequest,getNoOfPhotosPiechartDataSuccess}=dashboardSlice.actions
export default dashboardSlice.reducer