import React, { useEffect } from 'react'
import {createHashHistory} from "history"
import {HashRouter as Router,Route,Routes} from "react-router-dom"
import * as pages from "../pages"
import * as routesKey from "../constants/routes"
import ProtectedRoute from '../components/common/protectedroute'
import { useDispatch, useSelector } from 'react-redux'
import { setisAuthenticated } from '../redux/slices/authSlice'
export const history = createHashHistory();
const AppRouter = () => {
  const {isAuthenticated}=useSelector((state)=>state.auth)
  const dispatch = useDispatch();
  useEffect(() => {
    const isLogined = localStorage.getItem("isLogined");
    if (isLogined) {
      if (isLogined === "true" || isLogined === true) {
        dispatch(setisAuthenticated(true));
      } else { 
        dispatch(setisAuthenticated(false));
      }
    } else {
      dispatch(setisAuthenticated(false));
    }
  })
  return (
      <Router>
      <Routes>
        <Route element={<ProtectedRoute isAuthenticated={!isAuthenticated} redirect={"/"} /> }>
              <Route path={routesKey.LOGIN} element={<pages.Login />} />
        </Route>
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} redirect={"/login"} />} >
              <Route path={routesKey.DASHBOARD} element={<pages.Dashboard />} />
              <Route path={routesKey.ANIMALLIST} element={<pages.AnimalMangement />} />
              <Route path={routesKey.ADDANIMALLIST} element={<pages.AddAnimal />} />
              <Route path={routesKey.EDITANIMALLIST} element={<pages.EditAnimal />} />
              <Route path={routesKey.AGENTLIST} element={<pages.AgentManagement />} />
              <Route path={routesKey.USERSLIST} element={<pages.UserManagement />} />
              <Route path={routesKey.USERDETAILS} element={<pages.UserDetails />} />
              <Route path={routesKey.LOCATIONLIST} element={<pages.LocationMangement />} />
              <Route path={routesKey.ADDLOCATION} element={<pages.AddLocation />} />
              <Route path={routesKey.EDITLOCATION} element={<pages.EditLocation />} />
              <Route path={routesKey.ENQUERYLIST} element={<pages.Enqueries />} />
              {/* <Route path={routesKey.FONTSLIST} element={<pages.FontList />} /> */}
        </Route>
          </Routes>
    </Router>
  )
}

export default AppRouter