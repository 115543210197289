import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
    name: "location",
    initialState: {
        locationList: [],
        isLoading: false,
        totalLocationList: 0,
        locationDetails: {},
        previousLocationDetails:{}
    },
    reducers: {
        getLocationListRequest:(state)=>{
            state.isLoading = true;
        },
        getLocationListSuccess:(state,{payload})=>{
            state.isLoading = false;
            state.locationList = payload?.location_list
            state.totalLocationList=payload?.total_records
        },
        getLocationListFail:(state)=>{
            state.isLoading = false;
            state.locationList = []
            state.totalLocationList=0
        },
        getlocationDetailsRequest: (state) => {
            state.isLoading = true;
        },
        getlocationDetailsSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.locationDetails = payload
            state.previousLocationDetails = payload;
        },
        getlocationDetailsFail: (state) => {
            state.isLoading = false;
            state.locationDetails = {}
            state.previousLocationDetails = {}
        },
        setlocationImage: (state,{payload}) => {
            state.locationDetails.image = payload;
        },
        setlocationDetailsAnimals: (state, { payload }) => {
            state.locationDetails.animals = payload;
        }
    }
})

export default locationSlice.reducer;
export const {getLocationListFail,getLocationListRequest,getLocationListSuccess,getlocationDetailsFail,getlocationDetailsRequest,getlocationDetailsSuccess,setlocationDetailsAnimals,setlocationImage } = locationSlice.actions;