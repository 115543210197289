import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
// import Pagination from '../../components/common/pagination'
import { ReactComponent as SearchIcon } from "../../assets/icons/searchicon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setenquiresCurrentPageCurrentPage } from '../../redux/slices/paginationSlice';
import Pagination from '../../components/common/pagination';
import { getAllEnquiresList } from '../../services/api';
import moment from 'moment';

const Enquires = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("")
    const {enquiresCurrentPage}=useSelector((state)=>state.pagination)
    const {enquiryList,totalEnquires}=useSelector((state)=>state.enquires)
    const postPerPage = 10;
    const handleSearch = (e) => {
      setSearchText(e.target.value)
      dispatch(getAllEnquiresList(1, postPerPage, e.target.value))
      dispatch(setenquiresCurrentPageCurrentPage(1))
  }
  useEffect(() => {
    dispatch(getAllEnquiresList(enquiresCurrentPage,postPerPage,searchText))
    //eslint-disable-next-line
  },[enquiresCurrentPage,dispatch])
  return (
      <Template>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Enquiry List
          </p>
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
          <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
            <input
              className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
              placeholder="Search here"
              value={searchText}
              onChange={handleSearch}
            />
            <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between">
          {enquiryList?.length ? (
            <>{
            <table class="table-auto w-full">
              <thead>
                <tr>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Name
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Email
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Phone
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                    Enquiry
                                      </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                   Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {enquiryList?.map((customer) => (
                  <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer" >
                    <td className="text-sm py-4 px-4">
                      <div className="flex justify-start items-center flex-grow gap-4">
                        <div className="flex flex-col justify-start items-start flex-grow relative">
                          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                            {customer?.name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm p-4">{customer?.email} </td>
                    <td className="text-sm p-4">{customer?.phone} </td>
                    <td className="text-sm p-4">
                   {customer?.enquiry}
                        </td>
                    <td className="text-sm p-4 text-center">{customer?.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>}
            </>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Enqueries found
              </span>
            </div>
          )}
          <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={enquiresCurrentPage}
              totalCount={totalEnquires}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setenquiresCurrentPageCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
      </div>
    </Template>
  )
}

export default Enquires