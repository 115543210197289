import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated:true
    },
    reducers: {
        setisAuthenticated: (state, { payload }) => {
            state.isAuthenticated=payload
        }
    }
})

export default authSlice.reducer;
export const {setisAuthenticated}=authSlice.actions