import React, { useEffect, useState } from 'react'
import { ReactComponent as BackButton } from "../../assets/icons/arrowlefticon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionmarkicon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadicon.svg";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Template from '../../components/common/Template';
import MultiSelect from './multiSelect';
import Files from "react-files"
import { toast } from 'react-hot-toast';
import axiosConfig from '../../axios_config';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { useDispatch, useSelector } from 'react-redux';
import { getAllAnimalList, getLocationDetails } from '../../services/api';
import { setlocationDetailsAnimals, setlocationImage } from '../../redux/slices/locationSlice';
import moment from 'moment';
import { FromTimePicker, ToTimePicker } from './TimePicker';

const AddLocation = () => {
    const [isLoading, setisLoading] = useState(false)
    const {animalList}=useSelector((state)=>state.animal)
    const {locationDetails,previousLocationDetails}=useSelector((state)=>state.location)

    const {location_id } = useParams();

    

  const navigate = useNavigate();
  const initialValues = {
    name: locationDetails?.location_name ?locationDetails?.location_name :"",
    desc:locationDetails?.description ?locationDetails?.description:"",
    state:locationDetails?.address ? locationDetails?.address?.split(",")[0]:"",
    city:locationDetails?.address ? locationDetails?.address?.split(",")[1]:"",
    staying_available: locationDetails?.open ?locationDetails?.open:"",
    from_time: locationDetails?.timing ?moment(locationDetails?.timing?.split("-")[0],"hh:mm A").format("HH:mm"):"",
    to_time: locationDetails?.timing ?moment(locationDetails?.timing?.split("-")[1],"hh:mm A").format("HH:mm"):""
};
//Yup validator Schema
const EditInformationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(3, "Too short")
    .max(40, "Too Long")
        .required("Please enter  name"),
    desc:Yup.string().trim().required("required").min(20,"minimum 20 words").max(200,"maximum 200 words"),
    state:Yup.string()
    .trim()
    .min(3, "Too short")
    .required("enter your state name")
    .matches(/^[A-Za-z\s]*$/, "Please enter valid State name"),
    city:Yup.string()
    .trim()
    .min(3, "Too short")
    .required("enter Your City")
    .matches(/^[A-Za-z\s]*$/, "Please enter valid city name"),
    staying_available: Yup.boolean().required("required"),
    from_time: Yup.string().required("start time cannot be empty"),
    to_time: Yup.string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function(value) {
        const { from_time } = this.parent;
        return moment(value, "HH:mm").isSameOrAfter(moment(from_time, "HH:mm"));
      })
});
const {
    values,
    handleBlur,
    handleChange,
  handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EditInformationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async(values, action) => {
      if (locationDetails?.image && locationDetails?.animals?.length) {
        try {
          setisLoading(true);
          var formdata = new FormData();
          formdata.append("location_id", location_id);
          formdata.append("location_name", values.name);
          formdata.append("description", values.desc);
          if (typeof locationDetails?.image === "object") { 
            formdata.append("location_image", locationDetails?.image);
          }
          formdata.append("address",`${values.state},${values.city}`)
          formdata.append("timing",`${moment(values.from_time,"HH:mm").format("hh:mm A")}-${moment(values.to_time,"HH:mm").format("hh:mm A")}`)
          formdata.append("open",values.staying_available ?1:0)
          const { data } = await axiosConfig.post(`/edit/location`, formdata);
          const deletedAnimalIds = []
          previousLocationDetails?.animals?.forEach((location) => {
            if (!(locationDetails?.animals?.find((animal) => animal?.animal_id === location?.animal_id))) {
              deletedAnimalIds.push(location);
            }
          })
          const addedAnimalIds=[]
          locationDetails?.animals?.forEach((location) => {
            if (!(previousLocationDetails?.animals?.find((animal) => animal?.animal_id === location?.animal_id))) {
              addedAnimalIds.push(location?.animal_id);
            }
          })
          if (addedAnimalIds?.length) {
            const {data:addAnimalResp}=await axiosConfig.post(`/animal/add/by/location?location_id=${location_id}&animal_ids=${addedAnimalIds?.join(",")}`)
          }
          if (deletedAnimalIds?.length) {
            const {data:deleteAnimalResp}=await axiosConfig.put(`/delete/animal/by/location?animal_location_id=${deletedAnimalIds?.map(({animal_location_id})=>animal_location_id).join(",")}`)
          }
        if (data?.data?.status === "success") {
             toast.success(data?.data?.message || "Location added successfully")
             navigate("/location_list")
             setisLoading(false);
         } else {
            toast.error(data?.data?.message || "something went wrong" )
            setisLoading(false);
         }
        } catch (error) {
          toast.error("can't proceed your request right now!!")
          setisLoading(false);
        }
      } else if(!locationDetails?.image) {
        toast.error("Upload  one image of location")
      } else {
        toast.error("Select atleast one animal for this location")
      }
    },
  });

  const handleImageChange = (files) => {
    if (files?.length) {
      dispatch(setlocationImage(files[0]))
    }
  }

  const handleError = (error) => {
    toast.error(error?.message)
  }
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAllAnimalList(1,2000,""))
  },[dispatch])
  useEffect(() => {
    dispatch(getLocationDetails(location_id));
},[location_id,dispatch])

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    if (value.length > 200) {
      return;
    }
    handleChange(e)
  }

  const handleSelect = (filteredArray) => {
    dispatch(setlocationDetailsAnimals(filteredArray))
  }
  
  return (
      <Template>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex flex-col justify-center items-start flex-grow">
          <Link
            to={"/location_list"}
            className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 py-2"
          >
            <BackButton />
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left capitalize text-[#202223]">
                          Locations
            </p>
          </Link>
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-bold text-left capitalize text-[#202223]">
             Edit Location
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-left capitalize text-[#202223]">
              Location Details
            </p>
          </div>
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2">
          <button
            type="button"
            className="flex justify-start items-center cursor-pointer flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#6a9818]"
            style={
              isLoading
                ? {
                    boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)",
                    backgroundColor: "grey",
                  }
                : { boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }
            }
             onClick={handleSubmit}
             disabled={isLoading ? "disable" : ""}
          >
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              Save
            </p>
          </button>
        </div>
          </div>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-16 px-8 pt-12 pb-20 bg-white">
              <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[768px] gap-12">
                  {/* image */}
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
              Featured Image
              </p>
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-[13px] font-medium text-left">
                <span className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-[13px] font-medium text-left text-[#7b8794]">
                Select or upload a picture that should be attractive.
                </span>
              </p>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2">
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-4 flex-wrap">
              <Files
                        className="files-dropzone cursor-pointer"
                        onChange={(file) => handleImageChange(file)}
                        onError={handleError}
                        accepts={["image/png", "image/jpeg", "image/jpg"]}
                        maxFileSize={20000000}
                        minFileSize={0}
                        multiple
                        clickable
                      >
                        {locationDetails?.image ? (
                          <img
                            src={typeof locationDetails?.image==="object" ? locationDetails?.image?.preview?.url:locationDetails?.image}
                            alt="location_image"
                            className="h-[122px] w-[122px] object-cover"
                          />
                        ) : (
                          <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                            <UploadIcon />
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                              <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                                Upload
                              </p>
                            </div>
                          </div>
                        )}
                      </Files>
              </div>
              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <QuestionMarkIcon />
                <p className="flex-grow-0 flex-shrink-0 text-[11px] font-medium text-left capitalize text-[#7b8794]">
                Recommendations
                </p>
              </div>
            </div>
                  </div>
                  {/* Location Information */}
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                  {/* Heading..... */}
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[768px] text-xl font-semibold text-left text-[#202223]">
              Location Information
              </p>
                      </div>
                      {/* Name Field Start */}
                      <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  Name
                  </p>
                </div>
                <div className="flex-grow w-[608px]">
                  <input
                    type="text"
                    className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Enter Location Name"
                    value={values.name}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     name="name"
                     style={
                       errors.name && touched.name
                         ? { border: "1px solid red" }
                         : {}
                     }
                  />
                </div>
                      </div>
                      {/* Description FIeld Start */}
                      <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  Description
                  </p>
                </div>
                <div className="flex flex-col flex-grow w-[608px]">
                  <textarea
                    type="text"
                    className="form-input w-full h-40 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Type Here"
                    value={values.desc}
                     onChange={handleDescriptionChange}
                     onBlur={handleBlur}
                     name="desc"
                     style={
                       errors.desc && touched.desc
                         ? { border: "1px solid red" }
                         : {}
                     }
                ></textarea>
                  <div className="flex justify-between items-center">
                                {
                                  (values.desc && values.desc?.length < 20) &&
                                  <span className="text-red-500">20 minimum</span>
                                }
                                <div className="flex">
                                  <span className="text-green-500">{values.desc?.length}</span>/<span className="text-red-500">200</span>
                                </div>
                              </div>
                </div>
                      </div>
                      {/* Assign Animal to this Location */}
                      <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                 Animals
                  </p>
                </div>
                <div className="flex-grow w-[608px]">
                  <MultiSelect
                     selectedData={locationDetails?.animals}
                    allData={animalList}
                  value_key="name"
                  id_key={"animal_id"}
                  onSelect={handleSelect}
                  />
                </div>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  State
                  </p>
                </div>
                <div className="flex-grow w-[608px]">
                  <input
                    type="text"
                    className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Enter State Name"
                    value={values.state}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     name="state"
                     style={
                       errors.state && touched.state
                         ? { border: "1px solid red" }
                         : {}
                     }
                  />
                </div>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  City
                  </p>
                </div>
                <div className="flex-grow w-[608px]">
                  <input
                    type="text"
                    className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    placeholder="Enter City Name"
                    value={values.city}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     name="city"
                     style={
                       errors.city && touched.city
                         ? { border: "1px solid red" }
                         : {}
                     }
                  />
                </div>
                      </div>
                      <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  Staying Availability
                  </p>
                </div>
                <div className="flex-grow w-[608px]">
                  <select
                    type="text"
                    className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                    value={values.staying_available}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     name="staying_available"
                     style={
                       errors.staying_available && touched.staying_available
                         ? { border: "1px solid red" }
                         : {}
                     }
                >
                  <option value={""}>Select</option>
                  <option value={true}>Available</option>
                  <option value={false}>Not Available</option>
                  </select>
                </div>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                  <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                  Timing
                  </p>
                </div>
              <div className="flex-grow flex gap-3 w-[608px] self-center">
                <div className='w-1/2'>
                <div className="flex-grow-0 flex-shrink-0 flex flex-col gap-2">
                  <p className="text-[13px]  font-medium text-left text-black/80">
                  Open time
                  </p>
                <FromTimePicker error={errors.from_time&&touched.from_time} from_time={values.from_time} setValue={setFieldValue} />
                </div>
                </div>
                <div className='w-1/2'>
                <div className="flex-grow-0 flex-shrink-0 flex flex-col gap-2">
                  <p className="text-[13px] font-medium text-left text-black/80">
                  Close time
                    </p>
                <ToTimePicker error={errors.to_time&&touched.to_time} from_time={values.from_time} to_time={values.to_time} setValue={setFieldValue} />
                </div>
                </div>
                </div>
                      </div>
            </div>
        </div>
          </div>
    </Template>
  )
}

export default AddLocation