import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import * as routesKey from "../../constants/routes"
import {ReactComponent as DashBoardIcon} from "../../assets/icons/dashboardicon.svg"
import {ReactComponent as LogOutIcon} from "../../assets/icons/logouticon.svg"
import { ReactComponent as LocationIcon } from "../../assets/icons/location_icon.svg"
import { ReactComponent as AnimalIcon } from "../../assets/icons/animal_icon.svg"
import { ReactComponent as UserIcon } from "../../assets/icons/user_icon.svg"
import { ReactComponent as AgentIcon } from "../../assets/icons/agent_icon.svg"
import { ReactComponent as EnquiryIcon } from "../../assets/icons/enquire_icon.svg"
import { useDispatch } from "react-redux";
import { setisAuthenticated } from "../../redux/slices/authSlice";
import { useState } from "react";
const Navigation = () => {
  const menus = [
    {
      id:1,
      title: "Dashboard",
      link: routesKey.DASHBOARD,
      icon: <DashBoardIcon />,
    },
    {
      id:2,
      title: "Animal Management",
      link: routesKey.ANIMALLIST,
      icon: <AnimalIcon />,
    },
    {
      id:3,
      title: "Location Management",
      link: routesKey.LOCATIONLIST,
      icon: <LocationIcon />,
    },
    {
      id:4,
      title: "Agent Management",
      link: routesKey.AGENTLIST,
      icon: <AgentIcon />,
    },
    {
      id:6,
      title: "User Management",
      link: routesKey.USERSLIST,
      icon: <UserIcon />,
    },
    {
      id:5,
      title: "Enquires",
      link: routesKey.ENQUERYLIST,
      icon: <EnquiryIcon />,
    }
  ]


  return (
    <div className="flex flex-col justify-start items-start h-full bg-[#6a9818]">
      <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 h-16 gap-2.5 pl-6 pt-4">
        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-[3px]">
          <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left capitalize">
            <span className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left capitalize text-black">
              AL
            </span>
            <span className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left capitalize text-white">
              MS
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-between items-start flex-grow-0 flex-shrink-0 w-60 gap-1 p-3 min-h-[90vh]">
        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
          {
            menus.map((menu) => (
              menu.children ?
              <Disclosure key={menu.id}>
            {({ open }) => (
              <div
                      className={`${open ? "bg-white/5 transform" : ""
                        } flex flex-col self-stretch rounded-lg hover:bg-white/5 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 transition-all   `}
              >
                <Disclosure.Button className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 pl-4 pr-3 py-2.5 rounded-lg">
                    <div className="flex justify-start items-center flex-grow relative gap-4">
                      {menu.icon}
                      <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                       {menu.title}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className={`${
                        open ? "rotate-90 transform" : ""
                      } h-4 w-4 text-white transition-all`}
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 p-2 pt-0">
                          {
                            menu.children.map((submenu) => (
                              <Link to={submenu.link} key={submenu.id}  className={`flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative pl-[48px] pr-3 py-3 rounded hover:bg-white/5 transition-all`}>
                      <p className={`flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white`}>
                        {submenu.title}
                      </p>
                            </Link>
                            ))}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
                :
              <Link
                  to={menu.link}
            className={`flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 px-4 py-2.5 rounded-lg hover:bg-white/5  `}
          >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
              {menu.icon}
              <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white">
                {menu.title}
              </p>
            </div>
          </Link>
            ))
          }
        </div>
          <LogOutModal />
      </div>
    </div>
  );
};

export default Navigation;


const LogOutModal = () => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === 'Modal-Container') {
        setShowModal(false)
      }
    })
  }
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("isLogined")
    dispatch(setisAuthenticated(false))
  }
  return (
    <>
 <button
        onClick={()=>setShowModal(true)}
            className={`flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 px-4 py-2.5 rounded-lg hover:bg-white/5  `}
          >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
             <LogOutIcon />
              <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-white">
                logout
              </p>
            </div>
          </button>
   {showModal ? (
     <>
     <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-[#6a9818]/40" onClick={closeModal}>
         <div className="relative w-auto mx-auto">
           <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                           <div className="flex items-center justify-center gap-4 rounded-2xl flex-col p-4 w-[420px]">  
                  <div className="w-full flex justify-between p-2">
                    <span className="flex gap-1">
                    <svg width="20" height="20" viewBox="0 0 0.375 0.375" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m0.338 0.188 -0.075 0.081m0.075 -0.081 -0.075 -0.075m0.075 0.075H0.1m0.1 0.15H0.038v-0.3H0.2" stroke="#6a9818" stroke-width="0.025"/></svg>
                      Logout
                    </span>
               <button className="text-xl font-thin  mr-4" onClick={() => setShowModal(false)}>X</button>
                  </div>
                  <div>
                  <svg width="40" height="40" viewBox="0 0 0.375 0.375" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m0.338 0.188 -0.075 0.081m0.075 -0.081 -0.075 -0.075m0.075 0.075H0.1m0.1 0.15H0.038v-0.3H0.2" stroke="#6a9818" stroke-width="0.025"/></svg>
                  </div>
                           <div className="flex flex-col justify-center items-center  py-3">
                                   <p className="text-2xl font-semibold w-full text-center">Are you sure want to  logout?</p>
                               </div>
                               <div className="flex flex-col w-full gap-2 px-2">
                                   <div className="flex justify-center gap-2 w-full  ">
                                   <button className={` text-center px-3 py-2 rounded-2xl border-solid border border-gray-600 hover:bg-blue-600 hover:text-white cursor-pointer`} onClick={()=>setShowModal(false)}>Cancel</button>
                                   <button className={` text-center px-3 py-2 rounded-2xl border-solid border border-gray-600 hover:bg-red-600 hover:text-white cursor-pointer`} onClick={handleLogout}>Logout</button>
                                   </div>
                               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   ) : null}
 </>
  )
}