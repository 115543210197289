/*
All Api will be called here
*/

import axiosConfig from "../axios_config"
import { getAgentListFail, getAgentListRequest, getAgentListSuccess } from "../redux/slices/agentSlice";
import { getAnimalDetailsFail, getAnimalDetailsRequest, getAnimalDetailsSuccess, getAnimalListFail, getAnimalListRequest, getAnimalListSuccess } from "../redux/slices/animalSlice";
import { getNoOfPhotosPiechartDataFail, getNoOfPhotosPiechartDataRequest, getNoOfPhotosPiechartDataSuccess, getNoOfPostHistroicDataFail, getNoOfPostHistroicDataRequest, getNoOfPostHistroicDataSuccess } from "../redux/slices/dashboardslice";
import { getEnquiresListFail, getEnquiresListRequest, getEnquiresListSuccess } from "../redux/slices/enquiresSlice";
import { getLocationListFail, getLocationListRequest, getLocationListSuccess, getlocationDetailsFail, getlocationDetailsRequest, getlocationDetailsSuccess } from "../redux/slices/locationSlice";
import { getUserDetailsFail, getUserDetailsRequest, getUserDetailsSuccess, getUserListFail, getUserListRequest, getUserListSuccess } from "../redux/slices/userSlice";

export const getAllAnimalList = (page = 1, count = 10, search = "",is_active=1) => async (dispatch) => {
    try {
        dispatch(getAnimalListRequest())
        const { data } = await axiosConfig.get(`animal/get?search=${search}&page=${page - 1}&pageSize=${count}&is_active=${is_active}`);
        dispatch(getAnimalListSuccess(data?.data))
    } catch (error) {
        dispatch(getAnimalListFail())
    }
}

export const getAllLocationList = (page = 1, count = 10, search = "",is_active=1) => async (dispatch) => {
    try {
        dispatch(getLocationListRequest())
        const { data } = await axiosConfig.get(`/location/list?page=${page - 1}&page_size=${count}&search=${search}&status=${is_active}`);
        dispatch(getLocationListSuccess(data?.data))
    } catch (error) {
        dispatch(getLocationListFail())
    }
}

export const getLocationDetails = (location_id) => async (dispatch) => {
    try {
        dispatch(getlocationDetailsRequest())
        const { data } = await axiosConfig.get(`/location/details?location_id=${location_id}`)
        dispatch(getlocationDetailsSuccess(data?.data?.location_data))
    } catch (error) {
        dispatch(getlocationDetailsFail())
    }
}

export const getAllEnquiresList = (page = 1, count = 10, search = "") => async (dispatch) => {
    try {
        dispatch(getEnquiresListRequest())
        const { data } = await axiosConfig.get(`/enquiry/get?page=${page - 1}&pageSize=${count}&search=${search}`);
        dispatch(getEnquiresListSuccess(data?.data))
    } catch (error) {
        dispatch(getEnquiresListFail())
    }
}

export const getAllUsersList = (page = 1, count = 10, search = "") => async (dispatch) => {
    try {
        dispatch(getUserListRequest())
        const { data } = await axiosConfig.get(`/user/list?search=${search}&page=${page - 1}&page_size=${count}`);
        dispatch(getUserListSuccess(data?.data))
    } catch (error) {
        dispatch(getUserListFail())
    }
}
export const getAllAgentList = (page = 1, count = 10, search = "",is_active=1) => async (dispatch) => {
    try {
        dispatch(getAgentListRequest())
        const { data } = await axiosConfig.get(`/agent/list?search=${search}&page=${page - 1}&page_size=${count}&status=${is_active}`);
        dispatch(getAgentListSuccess(data?.data))
    } catch (error) {
        dispatch(getAgentListFail())
    }
}

export const getUserDetails = (user_id) => async (dispatch) => {
    try {
        dispatch(getUserDetailsRequest())
        const { data } = await axiosConfig.get(`/user/details?user_id=${user_id}`)
        dispatch(getUserDetailsSuccess(data?.data?.user_details))
    } catch (error) {
        dispatch(getUserDetailsFail())
    }
}

export const getAnimalDetails = (animal_id) => async (dispatch) => {
    try {
        dispatch(getAnimalDetailsRequest())
        const { data } = await axiosConfig.get(`/get/animal/details?animal_id=${animal_id}`)
        dispatch(getAnimalDetailsSuccess(data?.data))
    } catch (error) {
        dispatch(getAnimalDetailsFail())
    }
}

export const getAllPostHistroicData = (month,type,location_id) => async (dispatch) => {
    try {
        dispatch(getNoOfPostHistroicDataRequest())
        const {data}=await axiosConfig.get(`/get/animal/dashboard?month=${month}&type=${type}&location_id=${location_id?location_id:0}`)
        dispatch(getNoOfPostHistroicDataSuccess(data?.data?.dashboard))
    } catch (error) {
        dispatch(getNoOfPostHistroicDataFail())
    }
}

export const getNumberOfPhotosData = () => async (dispatch) => {
    try {
        dispatch(getNoOfPhotosPiechartDataRequest())
        const {data}=await axiosConfig.get(`/get/animal/pie/chart`)
        dispatch(getNoOfPhotosPiechartDataSuccess(data?.data?.dashboard))
    } catch (error) {
        dispatch(getNoOfPhotosPiechartDataFail())        
    }
}