import React, { useState } from "react";
import loginbackground from "../../assets/images/Group 35165.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../axios_config";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setisAuthenticated } from "../../redux/slices/authSlice";

const Login = () => {
  //http://15.206.255.108:8595/api/v1/login?username=9778915104&password=alml
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const dispatch = useDispatch();

  const login = async(values) => {
    try {
      setisLoading(true);
      const { data } = await axiosConfig.post(`login?username=${values.email}&password=${values.password}`);
      if (data?.data?.status === "SUCCESS") {
        toast.success(data?.data?.message || "login Successfully");
        localStorage.setItem("isLogined", true);
        navigate("/",{replace:true})
        setisLoading(false);
        dispatch(setisAuthenticated(true))
      } else {
        toast.error(data?.data?.message || "Something went wrong")
        setisLoading(false);
      }
    } catch (error) {
      toast.error("can't procees right now!!")
      setisLoading(false);
    }
  }

  return (
    <div className="relative max-h-screen overflow-hidden">
      <img
        src={loginbackground}
        className="w-full h-screen object-cover"
        alt=""
      />

      <div className="flex flex-1 space-x-64 justify-between items-center absolute top-20 left-60  p-2 ">
        <div className="flex flex-col pt-4">
          <h2 className="text-5xl tracking-wide underline text-white font-bold">
            Welcome Back!
          </h2>
          <p className="text-2xl font-semibold  tracking-wider  text-white pt-4">
            To maintain connectivity with our <br /> platform, kindly access the
            admin <br /> login using credentials .
          </p>
        </div>
        <div>
          <div className="w-full h-64">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string().required("username is required"),
                password: Yup.string().required("Password is required"),
              })}
              onSubmit={(values) => {
                login(values);
              }}
            >
              <Form>
                <div className="text-2xl rounded-lg bg-white pr-8 pl-8  pt-4 shadow-xl shadow-zinc-700  font-semibold">
                  <legend className=" text-3xl pt-4 mt-4 font-medium  text-center">
                    Login
                  </legend>
                  <div className="mt-8">
                    <div className="mb-4 p-4 relative text-base font-light pb-4">
                      <legend className=" text-base font-normal after:content-['*'] after:ml-0.5 after:text-red-500">
                        Username
                      </legend>
                      <Field
                        name="email"
                        type="text"
                        placeholder="Enter your Username"
                        className=" w-80   bg-[#D0FFC9] rounded-lg px-3 py-1 mt-1"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sm mt-1"
                      />
                    </div>
                    <div className="mb-8 p-4 relative text-base font-light pb-8">
                      <legend className=" text-base font-normal after:content-['*'] after:ml-0.5 after:text-red-500">Password</legend>
                      <Field
                        name="password"
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Enter your Password"
                        className="w-80  bg-[#D0FFC9] rounded-lg px-3 py-1 mt-1 "
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600 text-sm mt-1"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute top-2/4 right-6 transform -translate-y-1/4 text-black hover:text-gray-600"
                      >
                        {passwordVisible ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        )}
                      </button>
                      {/*forgot password impletation */}
                    </div>
                  </div>
                  <div className="mb-12 p-4 pb-12" style={{ marginTop: "-10px" }}>
                    <button
                      type="submit"
                      className="w-80 text-base bg-lime-300 text-black font-semibold py-1 rounded-lg"
                      disabled={isLoading ?"disable":""}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>

      <div className="text-center tracking-wider font-semibold text-sm whitespace-nowrap text-white absolute bottom-4 left-1/2 transform -translate-x-1/2">
        This platform is powered by the support of{" "}
        <span className="text-black">Kotak Mahindra</span>
        <span className="text-white"> and</span>{" "}
        <span className="text-black">Tata Steel</span>
      </div>
    </div>
  );
};

export default Login;
