import vectordp from "../../assets/images/profile.png"
import defaultProfile from "../../assets/images/default_profile.png"
export const imageOnError = (e) => {
    e.target.onerror = null;
    e.target.src = vectordp;
};

export const profileImageError = (e) => {
  e.target.onerror = null;
    e.target.src = defaultProfile;
}