import { createSlice } from "@reduxjs/toolkit";

const userSlice=createSlice({
    name: "user",
    initialState:{
        isLoading: false,
        usersList: [],
        totalUsers: 0,
        userDetails:{},
    },
    reducers: {
        getUserListRequest: (state) => {
            state.isLoading = true;
        },
        getUserListSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.usersList = payload?.user_list
            state.totalUsers=payload?.total_records
        },
        getUserListFail: (state) => {
            state.isLoading = false;
            state.usersList =[]
            state.totalUsers=0
        },
        getUserDetailsRequest: (state) => {
            state.isLoading = true;
        },
        getUserDetailsSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.userDetails = payload
        },
        getUserDetailsFail: (state) => {
            state.isLoading = false;
            state.userDetails ={}
        },
    }
})

export default userSlice.reducer;
export const {getUserListFail,getUserListRequest,getUserListSuccess ,getUserDetailsFail,getUserDetailsRequest,getUserDetailsSuccess} = userSlice.actions;