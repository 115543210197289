import React, { useRef, useState } from 'react'
import Template from '../../components/common/Template'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement,
  } from "chart.js";
  import { Bar, Doughnut, Line, getElementsAtEvent } from "react-chartjs-2";
import SingleSelect from './singleSelect';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllEnquiresList, getAllLocationList, getAllPostHistroicData, getNumberOfPhotosData } from '../../services/api';
const Dashboard = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        BarElement,
        ArcElement
      );
    const options = {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
        },
    };
    const [selectedLocations, setselectedLocations] = useState({
        location_id: 0,
        location_name: "All",
      });
    const chartRef = useRef();
    const noOfVisitorsChartRef = useRef();
    const [noOfVisitorsselectedLocations, setnoOfVisitorsselectedLocations] = useState({
        location_id: 0,
        location_name: "All",
      });
    const locations = [                             
        {
            location_id: 1,
            location_name:"Puri"
          },
        {
            location_id: 2,
            location_name:"Rourkela"
          },
    ]

    const animalPieChartData = [
        {
            label: "blackbox",
            no_of_image:10
        },
        {
            label: "Peacock",
            no_of_image:40
        },
        {
            label: "Olive",
            no_of_image:60
        },
    ]

    const [chartType, setchartType] = useState("bar");
    const [locationSearch, setlocationSearch] = useState("");
    const [noOfVisitorschartType, setnoOfVisitorschartType] = useState("bar");
    const [noOfVisitorslocationSearch, setnoOfVisitorslocationSearch] = useState("");
   const {noOfPostHistroicData,pieChartData,piechartDataLoading}= useSelector((state)=>state.dashboard)
   const {enquiryList}=useSelector((state)=>state.enquires)
   const {locationList}= useSelector((state)=>state.location)
    const [noOfVisitorsmonth, setnoOfVisitorsmonth] = useState(
        (new Date().getMonth() + 1).toString().padStart(2, "0")
      );
    const [month, setmonth] = useState(
        (new Date().getMonth() + 1).toString().padStart(2, "0")
      );
    const numberOfVisitors= [
        { "label": "January", "no_of_visitors": 0 }, { "label": "February", "no_of_visitors": 0 }, { "label": "March", "no_of_visitors": 0 }, { "label": "April", "no_of_visitors": 0 }, { "label": "May", "no_of_visitors": 0 }, { "label": "June", "no_of_visitors": 46 }, { "label": "July", "no_of_visitors": 227 }, { "label": "August", "no_of_visitors": 68 }, { "label": "September", "no_of_visitors": 7 }, { "label": "October", "no_of_visitors": 0 }, { "label": "November", "no_of_visitors": 0 }, { "label": "December", "no_of_visitors": 0 }
  ]

    const onSelectLocation = (value) => {
    setselectedLocations({ ...value });
    }
    const handleLocationSearch = (value) => {
        setlocationSearch(value);
    }
    const [type, settype] = useState("month");
    const [noOfVisitorsType, setnoOfVisitorsType] = useState("month")
    const onNoOfVisitorsSelectLocation = (value) => {
        setnoOfVisitorsselectedLocations({ ...value });
        }
        const handleNoOfVisitorsLocationSearch = (value) => {
            setnoOfVisitorslocationSearch(value);
    }
    
    const onClick = (event) => {
        if (type === "date") {
          return;
        }
        const activeElement = getElementsAtEvent(chartRef.current, event);
        if (activeElement.length > 0) {
          settype("date");
          const activeIndex = activeElement[0].index;
          switch (activeIndex) {
            case 0:
              setmonth("01");
              break;
            case 1:
              setmonth("02");
              break;
            case 2:
              setmonth("03");
              break;
            case 3:
              setmonth("04");
              break;
            case 4:
              setmonth("05");
              break;
            case 5:
              setmonth("06");
              break;
            case 6:
              setmonth("07");
              break;
            case 7:
              setmonth("08");
              break;
            case 8:
              setmonth("09");
              break;
            case 9:
              setmonth("10");
              break;
            case 10:
              setmonth("11");
              break;
            default:
              setmonth("12");
          }
        }
    };
    const onNoOfVisitorsBarClick = (event) => {
        if (noOfVisitorsType === "date") {
          return;
        }
        const activeElement = getElementsAtEvent(chartRef.current, event);
        if (activeElement.length > 0) {
          setnoOfVisitorsType("date");
          const activeIndex = activeElement[0].index;
          switch (activeIndex) {
            case 0:
              setnoOfVisitorsmonth("01");
              break;
            case 1:
                setnoOfVisitorsmonth("02");
              break;
            case 2:
                setnoOfVisitorsmonth("03");
              break;
            case 3:
                setnoOfVisitorsmonth("04");
              break;
            case 4:
                setnoOfVisitorsmonth("05");
              break;
            case 5:
                setnoOfVisitorsmonth("06");
              break;
            case 6:
                setnoOfVisitorsmonth("07");
              break;
            case 7:
                setnoOfVisitorsmonth("08");
              break;
            case 8:
                setnoOfVisitorsmonth("09");
              break;
            case 9:
                setnoOfVisitorsmonth("10");
              break;
            case 10:
                setnoOfVisitorsmonth("11");
              break;
            default:
                setnoOfVisitorsmonth("12");
          }
        }
    };
    
    const handleTypeChange = (value) => {
        settype(value);
        setmonth((new Date().getMonth() + 1).toString().padStart(2, "0"));
        setselectedLocations({ location_id: "", location_name: "All" });
  };

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAllPostHistroicData(month,type,selectedLocations.location_id))
  }, [dispatch, selectedLocations, type, month])
  
  useEffect(() => {
    dispatch(getNumberOfPhotosData());
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllEnquiresList(1,10,""))
    //eslint-disable-next-line
  },[dispatch])
  
    const handleNoOfVisitorTypeChange = (value) => {
        setnoOfVisitorsType(value);
        setnoOfVisitorsmonth((new Date().getMonth() + 1).toString().padStart(2, "0"));
        setnoOfVisitorsselectedLocations({ location_id: "", location_name: "All" });
    };
    
    const categoryColor = [
        "rgba(54, 162, 235, 0.64)",
        "rgba(153, 102, 255, 0.64)",
        "rgba(75, 192, 192, 0.64)",
        "rgba(201, 203, 207, 0.3)",
    ];
    
  useEffect(() => {
    dispatch(getAllLocationList(1,1000,""))
  },[dispatch])
  return (
      <Template>
           <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4 p-2">
              {/* 2nd part of graph */}
              <div  className="flex w-full max-w-full justify-between items-start  self-stretch flex-grow  p-4 rounded-md bg-white">
              <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative w-[70%] m-auto">
          <div className="flex  justify-between items-start gap-4 w-full">
        <div className="flex flex-col gap-1">
        {/* Paragraph */}
          <div className="flex">
            <p className="text-xl font-semibold text-left text-[#303049]">
              {type === "month"
                ? `No Of Post : 2023 ${
                    selectedLocations?.location_id
                      ? `in ${selectedLocations?.location_name}`
                      : ""
                                          }`
                : `No Of Post  : ${moment(`2023-${month}-01`).format(
                    "MMM YYYY"
                  )} ${
                    selectedLocations?.location_id
                      ? `in ${selectedLocations?.location_name} `
                      : ""
                  } ${
                    selectedLocations.location_id
                      ? ""
                      : ""
                  }`}
                              </p>
                              </div>
                              {/* Input for bar or line */}
                              <div className="flex gap-4">
            <div className="flex justify-start items-center relative gap-2 cursor-pointer">
              <input
                id="acceptTerms1"
                type="radio"
                className="form-radio border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 cursor-pointer"
                checked={chartType === "bar" ? true : false}
                onChange={() => setchartType("bar")}
              />
              <label
                for="acceptTerms1"
                className="form-check-label cursor-pointer"
              >
                <span className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#202223]">
                  Bar
                </span>
              </label>
            </div>
            <div className="flex justify-start items-center relative gap-2 cursor-pointer">
              <input
                id="acceptTerms2"
                type="radio"
                className="form-radio border-gray-300 text-[#186ced] shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 cursor-pointer"
                checked={chartType === "line" ? true : false}
                onChange={() => setchartType("line")}
              />
              <label
                for="acceptTerms2"
                className="form-check-label cursor-pointer"
              >
                <span className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#202223]">
                  Line
                </span>
              </label>
            </div>
                              </div>
                          </div>
                          <div className="flex gap-2">
          <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
            <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-[#828282]">
              Location
            </p>
            <SingleSelect
              selectedItem={selectedLocations}
              items={locationList}
              onSelect={onSelectLocation}
              keyname={"location_name"}
              id_key={"location_id"}
             searchValue={locationSearch}
             handleSearch={handleLocationSearch}
            />
                              </div>
                              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative">
              <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-[#828282]">
                By
              </p>
              <select
                className="form-select w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-black/30 pl-2.5 py-1.5 text-xs cursor-pointer"
                placeholder="Enter a name"
                value={type}
                onChange={(e) => handleTypeChange(e.target.value)}
              >
                <option value={"date"}>Monthly</option>
                <option value={"month"}>Yearly</option>
              </select>
            </div>
                          </div>
                          </div>
              <div className="flex justify-between w-full  items-center">
          <div className="w-full flex flex-col flex-shrink flex-grow ">  
        {chartType === "bar" ? (
          <Bar
            data={{
              labels: noOfPostHistroicData?.map(
                (post) => post?.label
              ),
              datasets: [
                {
                  label: "No Of Posts",
                  data: noOfPostHistroicData?.map(
                    (post) => post.posts
                  ),
                  backgroundColor: "rgb(106, 152, 24,0.5)",
                  borderColor: "rgb(106, 152, 24)",
                  borderWidth: 1,
                  minBarLength: 2,
                },
              ],
            }}
            options={{
              ...options,
              scales: {
                y: {
                  beginAtZero: true,
                },
                x: {
                  grid: {
                    offset: true,
                  },
                },
              },
              plugins: {
                title: "Orders",
                legend: {
                  display: false,
                  position: "top",
                },
              },
            }}
            onClick={onClick}
            ref={chartRef}
          />
        ) : (
          <Line
            data={{
              labels: noOfPostHistroicData?.map(
                (post) => post?.label
              ),
              datasets: [
                {
                  label: "Bookings",
                  data: noOfPostHistroicData?.map(
                    (post) => post.posts
                  ),
                  backgroundColor: "#fff",
                  borderColor: "rgb(106, 152, 24)",
                  tension: 0.2,
                  spanGaps: true
                },
              ],
            }}
            options={{
              ...options,
              scales: {
                y: {
                  beginAtZero: true,
                },
                x: {
                  grid: {
                    offset: true,
                  },
                },
              },
              plugins: {
                title: "hii",
                legend: {
                  display: false,
                  position: "top",
                },
              },
            }}
            ref={chartRef}
            responsive="true"
          />
        )}
          </div>
    </div>
                  </div>
   {   piechartDataLoading ?<span>loading.......</span> :           <div
        className="flex flex-col justify-start items-start self-stretch flex-grow gap-6 p-6 rounded-md bg-white"
        style={{
          boxShadow:
            "0px 1px 2px 0 rgba(0,0,0,0.06), 0px 1px 1px 0 rgba(0,0,0,0.02)",
        }}
          >
     {pieChartData?.reduce(
                            (curval, item) =>
                              curval + item?.posts,
                            0
            ) >0 ? 
            <>
        <div className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
          <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-[#303049]">
            No Of Posts
          </p>
        </div>
        <div className="flex flex-col justify-center items-center self-stretch flex-grow relative gap-10 w-[220px]">
          <Doughnut
            data={{
              labels: pieChartData?.map((item) => item?.label),
              datasets: [
                {
                  label: "Posts",
                  data: pieChartData?.map(
                    (item) => item.posts
                  ),
                  backgroundColor: categoryColor,
                  hoverOffset: 0,
                  borderWidth: 0,
                },
              ],
            }}
            options={options}
            width={100}
            height={100}
            // width="156px"
            // height="156px"
          />
          <div className="flex flex-col justify-start items-start self-stretch flex-grow gap-4">
            {pieChartData?.map((item, i) => (
              <div
                key={i}
                className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0"
              >
                <div className="flex justify-start items-center flex-grow relative gap-2">
                  <div
                    className="flex-grow-0 flex-shrink-0 w-2.5 h-2.5 relative overflow-hidden rounded-[3px]"
                    style={{ backgroundColor: categoryColor[i] }}
                  >
                    <div className="w-2.5 h-2.5 absolute left-[-1px] top-[-1px]" />
                  </div>
                  <p className="flex-grow-0 flex-shrink-0 text-[13px] font-medium text-left text-[#202124] capitalize">
                    {item?.label}
                  </p>
                </div>
                <div className="flex justify-start items-center relative gap-1 rounded w-12">
                  <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-[#202124]">
                    {item?.posts}
                  </p>
                </div>
                <div className="flex justify-end items-center relative gap-1 rounded w-12">
                  <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-[#202124]">
                    {`${Number(
                      (
                        (item?.posts /
                          pieChartData?.reduce(
                            (curval, item) =>
                              curval + item?.posts,
                            0
                          )) *
                        100
                      ).toFixed(1)
                    )}%`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        </>:null}
      </div>}
              </div>
              {/* Enquires list */}
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2">
              <div
                className="flex flex-col justify-start items-start flex-grow rounded-md bg-white overflow-hidden"
                style={{
                  boxShadow:
                    "0px 1px 2px 0 rgba(0,0,0,0.06), 0px 1px 1px 0 rgba(0,0,0,0.02)",
                }}
              >
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3 px-6 pt-6 pb-2">
                  <p className="flex-grow w-[1088px] text-base font-semibold text-left text-[#303049]">
                    Enqueries
                  </p>
                </div>
            {   enquiryList?.length ? <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="text-xs capitalize font-semibold px-6 py-3 text-left bg-blue-100">
                        Enquiry Id
                      </th>
                      <th className="text-xs capitalize font-semibold px-6 py-3 text-left bg-blue-100">
                        Name
                      </th>
                      <th className="text-xs capitalize font-semibold px-6 py-3 text-left bg-blue-100">
                        Email
                      </th>
                      <th className="text-xs capitalize font-semibold px-6 py-3 text-left bg-blue-100">
                        Phone
                      </th>
                      <th className="text-xs capitalize font-semibold px-6 py-3 text-left bg-blue-100">
                        Enquiry 
                      </th>
                      <th className="text-xs capitalize font-semibold px-6 py-3 text-left bg-blue-100">
                      Date 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiryList?.map((enquiry, i) => (
                      <tr
                        key={i}
                        className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5"
                      >
                        <td
                          className="text-sm py-4 px-6"
                        >
                          <button
                            type="button"
                            className="m-0 p-0 hover:underline"
                          >
                            {enquiry?.enquiry_id}
                          </button>
                        </td>
                        <td className="text-sm py-4 px-6">
                          {enquiry?.name}
                        </td>
                        <td className="text-sm py-4 px-6">
                          {enquiry?.email}
                        </td>
                        <td className="text-sm py-4 px-6">
                          {enquiry?.phone}
                        </td>
                        <td className="text-sm py-4 px-6">
                          {enquiry?.enquiry}
                        </td>
                          <td className="text-sm py-4 px-6">
                          {enquiry?.date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Enquries found
              </span>
            </div>}
              </div>
            </div>
      </div>
    </Template>
  )
}

export default Dashboard