import { TimePicker } from "antd";
import { toast } from "react-hot-toast";
import {useState,useEffect} from "react"
import moment from "moment/moment";

export const FromTimePicker = ({ error,from_time,setValue}) => {
    const handleTimeChange = (time, timeStr) => {
      if (moment(timeStr,"hh:mm A").format("HH:mm")==="Invalid date") {
        return;
      } else {
        if (moment(timeStr, "hh:mm A").format("HH:mm") === "Invalid date") {
          return;
        }
         setValue(
           "from_time",moment(timeStr,"hh:mm A").format("HH:mm")
        )
         setValue(
           "to_time",""
        )
      }
    }
    const handleTimeSelect = (time, timeStr) => {
      if (time) {
        setValue(
            "from_time",moment(time?._d).format("HH:mm")
          )
          setValue(
            "to_time",""
         )
        }
    }
    return(
      <TimePicker
      format="hh:mm A"
    className={`formInput text-white w-full ${error&& 'border-red-500 border-2 border-solid'} `}
    onChange={handleTimeChange}
    allowClear={false}
    value={moment(from_time,"HH:mm").isValid() ?moment(from_time,"hh:mm A"):""}
    use12Hours={true}
    inputReadOnly={true}
    minuteStep={5}
    showNow={false}
     style={{color:"white"}}
    onSelect={handleTimeSelect}
  />
    )
  }
export  const ToTimePicker= ({error,from_time,to_time,setValue }) => {
    const [disabled, setDisabled] = useState(true);
    function checkDisabled() {
        if (!from_time) {
            setDisabled(true);
            toast.error("please enter from time first")
        }else{
            setDisabled(false)
        }
    }
      function checkIfDisabled() {
          if (!from_time) {
              return false;
        } else {
            return true
        }
    }
  
    useEffect(() => {
      setDisabled(!checkIfDisabled());
      //eslint-disable-next-line
    },[from_time,to_time])
  
    
  
  
  
    const handleTimeChange = (time, timeStr) => {
        if (moment(timeStr,"hh:mm A").format("HH:mm")==="Invalid date") {
            return;
          } else {
            if (moment(timeStr, "hh:mm A").format("HH:mm") === "Invalid date") {
              return;
            }
             setValue(
               "to_time",moment(timeStr,"hh:mm A").format("HH:mm")
            )
          }
    }

    const disabledMinutes = (selectedHour) => {
        let disableMinute = [];
        if (selectedHour === Number(from_time?.split(":")[0])) {
            for (let i = 0; i < Number(from_time?.split(":")[1]); i++){
                disableMinute.push(i);
            }
      }
    };
    const disabledHours = () => {
      const disabledHoursSet = new Set();
      const disabledHours = [];  
      for (let i = 0; i <= Number(from_time?.split(":")[0]); i++){
        disabledHours.push(Number(i))
      }
        const disabledHoursSetArray = [...disabledHoursSet, ...disabledHours];
        var unique = [...new Set(disabledHoursSetArray)];
        return Array.from(unique);
    };
    const handleTimeSelect = (time, timeStr) => {
      if (time) { 
        setValue(
            "to_time",moment(time?._d).format("HH:mm")
         )
      }
    }
    return(
      <TimePicker
           disabledTime={() => ({
                disabledHours: disabledHours,
                disabledMinutes:disabledMinutes
         })}
        disabled={disabled}
        format="hh:mm A"
      className={`formInput text-black w-full ${error && 'border-red-500 border-2 border-solid'} `}
      onChange={handleTimeChange}
      onClick={checkDisabled}
      allowClear={false}
       value={moment(to_time,"HH:mm").isValid() ?moment(to_time,"hh:mm A"):""}
      use12Hours={true}
      inputReadOnly={true}
      minuteStep={5}
      showNow={false}
      style={{ color: "black" }}
     onSelect={handleTimeSelect}
   />
    )
  }