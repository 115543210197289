import { createSlice } from "@reduxjs/toolkit";

const animalSlice = createSlice({
    name: "animal",
    initialState: {
        isLoading: false,
        animalList: [],
        totalAnimals: 0,
        animalDetails: {},
        animalImage:[]
    },
    reducers: {
        getAnimalListRequest: (state) => {
            state.isLoading=true
        },
        getAnimalListSuccess: (state,{payload}) => {
            state.isLoading = false
            state.animalList = payload?.animal_List
            state.totalAnimals=payload?.totalCount
            state.allAnimalList=payload?.animal_List
        },
        getAnimalListFail: (state) => {
            state.isLoading = false
            state.animalList = []
            state.allAnimalList=[]
            state.totalAnimals=0
        },
        setAnimalDetails: (state,{payload}) => {
            state.animalDetails=state.animalList.find((animal)=>Number(animal?.animal_id)===Number(payload))
        },
        filterAnimalList: (state,{payload}) => {
            state.animalList=state.allAnimalList.filter((animal)=>animal?.name?.toLowerCase().includes(payload?.toLowerCase()))
        },
        setAllAnimalList: (state) => {
            state.animalList = state.allAnimalList;
        },
        getAnimalDetailsRequest: (state) => {
            state.animalDetailsLoading = true;
        },
        getAnimalDetailsSuccess: (state,{payload}) => {
            state.animalDetailsLoading = false;
            state.animalDetails = payload?.animal_details
            state.animalImage=payload?.animal_images
        },
        getAnimalDetailsFail: (state) => {
            state.animalDetailsLoading = false;
            state.animalDetails = {}
            state.animalImage=[]
        },
        setAnimalImage:(state,{payload})=>{
            state.animalDetails.thumb_image = payload;
        }
    }
})

export default animalSlice.reducer;
export const {getAnimalListFail,getAnimalListRequest,getAnimalListSuccess,setAnimalDetails,filterAnimalList,setAllAnimalList,getAnimalDetailsFail,getAnimalDetailsRequest,getAnimalDetailsSuccess,setAnimalImage}=animalSlice.actions