import React, { useEffect, useState } from 'react'
import { ReactComponent as AddIcon } from "../../../src/assets/icons/addicon.svg";
import { ReactComponent as UploadIcon } from "../../../src/assets/icons/uploadicon.svg";
import * as Yup from "yup"
import { useFormik } from 'formik';
import Files from "react-files"
import { toast } from 'react-hot-toast';
import { getAllAgentList, getAllLocationList } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import axiosConfig from '../../axios_config';

const AddAgent = () => {
    const [showModal, setShowModal] = useState(false);
    const [agentProfile, setagentProfile] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const {locationList}=useSelector((state)=>state.location)
    const initialValues = {
        name: "",
        email:"",
        address: "",
        location:"select",
        user_id:"",
       password: "",
    };
    //Yup validator Schema
    const EditInformationSchema = Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "Too short")
        .max(40, "Too Long")
            .required("Please enter  name"),
        email:Yup.string().trim().required("required").matches( /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/gm,"not a valid email"),
        address: Yup.string().required(),
        location: Yup.string().notOneOf(["select"], "please Select discount_type").required("required"),
        user_id:Yup.string()
        .trim()
        .min(3, "Too short")
        .max(40, "Too Long")
            .required("Please enter  user Id"),
      password: Yup.string().required("enter password"),
      phone:Yup.string().matches(/^[6-9]\d{9}$/,"Not a Valid Phone Number").required("please enter Your number").min(10).max(10)
    });
    const {
        values,
        resetForm,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
      } = useFormik({
        initialValues,
        validationSchema: EditInformationSchema,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnBlur: false,
        onSubmit: async(values, action) => {
            if (agentProfile) {
                try {
                    var formdata = new FormData();
                formdata.append("agent_name", values.name);
                formdata.append("email", values.email);
                formdata.append("phone", values.phone);
                formdata.append("address", values.address);
                formdata.append("user_name", values.user_id);
                formdata.append("password", values.password);
                formdata.append("location_id", values.location);
                formdata.append("profile_image", agentProfile);
                setisLoading(true)
                const { data } = await axiosConfig.post(`/save/agent`, formdata)
                if (data?.data?.status === "success") {
                    toast.success(data?.data?.message || "Agent add successfully")
                    setShowModal(false)
                    resetForm();
                    dispatch(getAllAgentList())
                    setagentProfile("")
                setisLoading(false)
                } else {
                    toast.error(data?.data?.message || "something went wrong")
                setisLoading(false)
                }
                } catch (error) {
                    setisLoading(false)
                    toast.error("can't procced right now")
                }
            } else {
                toast.error("upload agent profile image")
            }
        },
      });
    
    const handleImageChange = (files) => {
        if (files?.length) {
            setagentProfile(files[0])
        }
    }

    const handleError = (error) => {
        toast.error(error?.message)
    }
const dispatch=useDispatch()
    useEffect(() => {
        dispatch(getAllLocationList(1,2000,""))
    },[dispatch])
    
    const openModal = () => {
        setShowModal(true);
        resetForm()
        setagentProfile("")
    }
    const closeModal = (e) => {
        e?.target?.classList?.forEach((classes) => {
          if (classes === 'Modal-Container') {
            setShowModal(false)
          }
        })
        }
    return (
        <>
            <div
                className="flex justify-start items-start flex-grow-0 flex-shrink-0 h-[42px] gap-2.5 rounded-[5px]"
            >
                <button
                    className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-[#6a9818]"
                    style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                    onClick={openModal}
                >
                    <AddIcon />
                    <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                        Add Agent
                    </p>
                </button>
            </div>


            {showModal ? (
                <>
                    <div
                        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-[#6a9818]/30 " onClick={closeModal}
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-full">
                            <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start gap-1 p-2 border-b border-solid border-gray-300 rounded-t flex-col ">
                                    <h3 className="text-xl font=semibold">Add Agent</h3>
                                    <div className="relative p-6 pt-0 flex-auto">
                                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1">
                                            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1 flex-col">
                                                <div className="flex  w-[900px] gap-[10px]">
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Profile Image
                                                        </p>
                                                        <div className="flex-grow w-[300px]">
                                                        <Files
                        className="files-dropzone cursor-pointer"
                        onChange={(file) => handleImageChange(file)}
                        onError={handleError}
                        accepts={["image/png", "image/jpeg", "image/jpg"]}
                        maxFileSize={20000000}
                        minFileSize={0}
                        multiple
                        clickable
                      >
                        {agentProfile ? (
                          <img
                            src={agentProfile?.preview?.url}
                            alt="equipment_image"
                            className="h-[122px] w-[122px] object-cover rounded-full"
                          />
                        ) : (
                          <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 h-[122px] w-[122px] relative gap-1 px-[23.5px] py-7 rounded-full bg-[#186ced]/[0.04] border-[1.33px] border-[#186ced]/[0.64] border-dashed">
                            <UploadIcon />
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 px-[5px] py-0.5 rounded">
                              <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-center text-black/[0.64]">
                                Upload
                              </p>
                            </div>
                          </div>
                        )}
                      </Files>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Name
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px]">
                                                            <input
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter Name "
                                                                name='name'
                                                                value={values.name}
                                                                style={errors.name && touched.name ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Phone
                                                        </p>
                                                        <div className="flex gap-3 flex-grow w-[300px]">
                                                            <input
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                                                                type={"number"}
                                                                placeholder="Enter Phone "
                                                                name='phone'
                                                                value={values.phone}
                                                                style={errors.phone && touched.phone ?{border:"1px solid red"}:{}}
                                                            onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex  w-[900px] gap-[10px]">
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Email
                                                        </p>
                                                        <div className="flex-grow w-[300px]">
                                                            <input
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter email"
                                                                name='email'
                                                                value={values.email}
                                                                style={errors.email && touched.email ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Address
                                                        </p>
                                                        <div className="flex-grow w-[300px]">
                                                            <input
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter Address"
                                                                name="address"
                                                                value={values.address}
                                                                style={errors.address && touched.address ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Location
                                                        </p>
                                                        <div className="flex-grow w-[300px]">
                                                            <select
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm capitalize"
                                                                name="location"
                                                                value={values.location}
                                                                style={errors.location && touched.location ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option value={"select"}>Select</option>
                                                                {
                                                                    locationList?.map((location,i) => (
                                                                        <option className='capitalize' value={location?.location_id} key={i}>{ location?.location_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex  w-[900px] gap-[10px]">
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                        <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                            Username For Login
                                                        </p>
                                                        <div className="flex-grow w-[300px]">
                                                            <input
                                                                className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                                                                type={"text"}
                                                                placeholder="Enter Username"
                                                                name="user_id"
                                                                value={values.user_id}
                                                                style={errors.user_id && touched.user_id ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-0 flex-shrink-0 w-auto">
                                            <p className="text-[13px] leading-10 py-[1px] font-medium text-left text-black/80">
                                                Password
                                            </p>
                                            <div className="flex-grow w-[300px]">
                                                <input
                                                    className="form-input w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder:text-sm"
                                                    type={"text"}
                                                    placeholder="Enter  Password"
                                                    name="password"
                                               value={values.password}
                                                                style={errors.password && touched.password ?{border:"1px solid red"}:{}}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="flex justify-end w-full p-6">
                                        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2 self-end">
                                            <div
                                                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[11px] rounded-[94px] bg-[#fff] cursor-pointer"
                                                style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
                                                onClick={() => setShowModal(false)}
                                            >
                                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-black">
                                                    Cancel
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 px-5 py-[11px] rounded-[94px] bg-[#6a9818] cursor-pointer"
                                                onClick={handleSubmit}
                    disabled={isLoading?"disable":""}
                                            >
                                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
                                                    Save
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}


        </>

    )
}

export default AddAgent