import React, { useEffect,Fragment } from 'react'
import Template from '../../components/common/Template'
import { Link, useParams } from 'react-router-dom'
import { imageOnError } from '../../components/utils/imageOnError';
import { ReactComponent as BackButton } from "../../assets/icons/arrowlefticon.svg";
import { Tab } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../services/api';
import moment from 'moment';
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
const UserDetails = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const {userDetails}=useSelector((state)=>state.user)
  useEffect(() => {
    dispatch(getUserDetails(user_id))
  },[user_id,dispatch])
  return (
      <Template>
           <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex flex-col justify-center items-start flex-grow">
          <Link to={"/users_list"} className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 py-2">
          <BackButton />
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left capitalize text-[#202223]">
              Users
            </p>
          </Link>
          <div className="flex  justify-between items-center w-full flex-grow-0 flex-shrink-0 relative">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-bold text-left capitalize text-[#202223]">
              {userDetails?.user_name ? userDetails?.user_name:"Unknown"}
            </p>
            <img
                            className="w-16 h-16 rounded-full object-cover"
                            src={userDetails?.image?userDetails?.image:'/profile.png'}
                            alt="preview"   effect="blur"
                            onError={imageOnError}
                          />
          </div>
        </div>
          </div>
        {/* User Details */}
        <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
        <div className="flex flex-col justify-start items-start self-stretch flex-grow">
          <Tab.Group>
          <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-8 border border-black/[0.08]">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   User Details
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                    User Activity
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0">
              <Tab.Panel className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0">
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-16 px-8 pt-12 pb-20 bg-white">
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
                  <p className="self-stretch flex-grow-0 flex-shrink-0 w-[698px] text-xl font-semibold text-left text-[#202223]">
                   User Details
                  </p>
                </div>
                
                <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative">
                  {/* Item Start */}
                  <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 py-3 border-b">
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-6 font-medium text-left text-black/80">
                      Name
                      </p>
                    </div>
                    <div className="flex-grow w-[608px]">
                      <p className="text-base font-medium text-left capitalize text-black">
                      {userDetails?.user_name}
                      </p>
                    </div>
                  </div>
                  {/* Item End */}
                  <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 py-3 border-b">
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-6 font-medium text-left text-black/80">
                     Email
                      </p>
                    </div>
                    <div className="flex-grow w-[608px]">
                      <p className="text-base font-medium text-left capitalize text-black">
                      {userDetails?.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 py-3 border-b">
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-6 font-medium text-left text-black/80">
                     Phone
                      </p>
                    </div>
                    <div className="flex-grow w-[608px]">
                      <p className="text-base font-medium text-left capitalize text-black">
                      {userDetails?.phone}
                      </p>
                    </div>
                    </div>
                  <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 py-3 border-b">
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-6 font-medium text-left text-black/80">
                     Total Post
                      </p>
                    </div>
                    <div className="flex-grow w-[608px]">
                      <p className="text-base font-medium text-left capitalize text-black">
                      {userDetails?.live_images?.length +userDetails?.not_live_images?.length}
                      </p>
                    </div>
                    </div>
                  <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 py-3 border-b">
                    <div className="flex-grow-0 flex-shrink-0 w-[150px]">
                      <p className="text-[13px] leading-6 font-medium text-left text-black/80">
                      Account Creation Date
                      </p>
                    </div>
                    <div className="flex-grow w-[608px]">
                      <p className="text-base font-medium text-left capitalize text-black">
                       {moment(userDetails?.creation_date,"DD-MM-YYYY").format("Do MMM,YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </Tab.Panel>
            <Tab.Panel className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0">
           { userDetails?.live_images?.length || userDetails?.not_live_images?.length ?  <table class="table-auto w-full">
                <thead>
                        <tr>
                          <th className="border-b border-black/10 text-xs font-semibold px-4 pl-0 py-3 text-left w-[420px]">
                          Location Name
                          </th>
                          <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                            Caption
                          </th>
                          <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                            Likes
                          </th>
                          <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                            Comments
                          </th>
                          <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                           Views
                          </th>
                          <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                           Status
                          </th>
                        </tr>
                      </thead>
                  <thead>
                  </thead>
                  <tbody>
                    {
                      userDetails?.live_images?.map((post) => (  
                      <tr className="group/item bg-white hover:bg-slate-100 transition-all border border-black/5 mb-4">
                      <td className="text-sm p-4">
                        <div className="flex justify-start items-center flex-grow gap-4">
                              <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-14 h-14 overflow-hidden rounded-md bg-[#eaecf4]">
                                <img src={post?.animal_image} alt={post?.location_name} className="h-full w-full object-cover" onError={imageOnError} />
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow relative">
                            <p className="self-stretch text-sm font-medium text-left text-[#202223]">
                              {post?.location_name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-sm p-4">
                        <div className="flex flex-col justify-center items-start">
                          <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left capitalize text-[#202223]">
                            {post?.message}
                          </p>
                        </div>
                      </td>
                          <td className='text-sm p-4'>
                            {post?.like}
                      </td>
                          <td className='text-sm p-4'>
                            {post?.comments?.length}
                          </td>
                          <td className='text-sm p-4'>
                            {post?.view}
                          </td>
                          
                      <td className="text-sm p-4">
                      <div className="flex justify-start">
                <div className="flex w-auto px-2 rounded-full bg-green-200 text-[11px] font-medium">
                Active
                </div>
              </div>
                      </td>
                    </tr>
                      ))}
                      {
                      userDetails?.not_live_images?.map((post) => (  
                        <tr className="group/item bg-white hover:bg-slate-100 transition-all border border-black/5 mb-4">
                      <td className="text-sm p-4">
                        <div className="flex justify-start items-center flex-grow gap-4">
                              <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-14 h-14 overflow-hidden rounded-md bg-[#eaecf4]">
                                <img src={post?.animal_image} alt={post?.location_name} className="h-full w-full object-cover" onError={imageOnError} />
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow relative">
                            <p className="self-stretch text-sm font-medium text-left text-[#202223]">
                              {post?.location_name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-sm p-4">
                        <div className="flex flex-col justify-center items-start">
                          <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left capitalize text-[#202223]">
                            {post?.message}
                          </p>
                        </div>
                      </td>
                          <td className='text-sm p-4'>
                            {post?.like}
                      </td>
                          <td className='text-sm p-4'>
                            {post?.comments?.length}
                          </td>
                          <td className='text-sm p-4'>
                            {post?.view}
                          </td>
                          
                      <td className="text-sm p-4">
                      <div className="flex justify-start">
                <div className="flex w-auto px-2 rounded-full bg-orange-200 text-[11px] font-medium">
                Pending
                </div>
              </div>
                      </td>
                    </tr>
                      ))}
                  
                  </tbody>
                </table>:<div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No User Activity Found
              </span>
            </div>}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
            </div>
            </div>
    </Template>
  )
}

export default UserDetails