import { createSlice } from "@reduxjs/toolkit";

const paginationSlice = createSlice({
    name: "pagination",
    initialState: {
        userManagementCurrentPage:1,
        enquiresCurrentPage: 1,
        locationManagementCurrentPage:1,
        animalManagementCurrentPage: 1,
        agentManagementCurrentPage: 1,
        deactivatedagentManagementCurrentPage: 1,
        deactivedAnimalManagementCurrentPage:1,
        deactivatedLocationManagementCurrentPage:1,
    },
    reducers: {
        setuserManagementCurrentPage: (state, { payload }) => {
            state.userManagementCurrentPage = payload;
        },
        setenquiresCurrentPageCurrentPage: (state, { payload }) => {
            state.enquiresCurrentPage = payload;
        },
        setlocationManagementCurrentPageCurrentPage: (state, { payload }) => {
            state.locationManagementCurrentPage = payload;
        },
        setAnimalManagementCurrentPageCurrentPage: (state, { payload }) => {
            state.animalManagementCurrentPage = payload;
        },
        setDeactivatedAnimalManagementCurrentPageCurrentPage: (state, { payload }) => {
            state.deactivedAnimalManagementCurrentPage = payload;
        },
        setAgentManagementCurrentPage: (state, { payload }) => {
            state.agentManagementCurrentPage = payload;
        },
        setDeactivatedAgentManagementCurrentPage: (state, { payload }) => {
            state.deactivatedagentManagementCurrentPage = payload;
        },
        setDeactivatedLocationManagementCurrentPage: (state, { payload }) => {
            state.deactivatedLocationManagementCurrentPage = payload;
        },
    }
})
export default paginationSlice.reducer;
export const {setuserManagementCurrentPage ,setenquiresCurrentPageCurrentPage,setlocationManagementCurrentPageCurrentPage,setAnimalManagementCurrentPageCurrentPage,setAgentManagementCurrentPage,setDeactivatedAnimalManagementCurrentPageCurrentPage,setDeactivatedAgentManagementCurrentPage,setDeactivatedLocationManagementCurrentPage,} = paginationSlice.actions;