import logo from './logo.svg';
import './App.css';
import AppRouter from './routers';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
    <Provider store={store}>
      <AppRouter />
    </Provider>
      <Toaster />
    </>
  );
}

export default App;
