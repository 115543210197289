import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import paginationSlice from "./slices/paginationSlice";
import animalSlice from "./slices/animalSlice";
import locationSlice from "./slices/locationSlice";
import enquiresSlice from "./slices/enquiresSlice";
import userSlice from "./slices/userSlice";
import agentSlice from "./slices/agentSlice";
import dashboardslice from "./slices/dashboardslice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        pagination: paginationSlice,
        animal: animalSlice,
        location: locationSlice,
        enquires: enquiresSlice,
        user: userSlice,
        agent: agentSlice,
        dashboard:dashboardslice
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
