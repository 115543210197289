import React from "react";
import Navigation from "./Navigation";
// import Header from "./Header";

const Template = ({ children }) => {
  
  return (
    <div className="flex min-w-full min-h-screen bg-[#f3f5f8]">
      <div className="bg-[#6a9818] fixed left-0 top-0 min-h-screen z-10">
        <Navigation />
      </div>
      <div className="min-w-full pl-[240px]">
        {/* <Header /> */}
        {children}
      </div>
    </div>
  );
};

export default Template;
