import React, { useEffect, useState } from 'react'
import Template from '../../components/common/Template'
import { ReactComponent as SearchIcon } from "../../assets/icons/searchicon.svg";
import { imageOnError } from '../../components/utils/imageOnError';
import { useDispatch, useSelector } from 'react-redux';
import { setuserManagementCurrentPage } from '../../redux/slices/paginationSlice';
import Pagination from '../../components/common/pagination';
import { useNavigate } from 'react-router-dom';
import { getAllUsersList } from '../../services/api';

const UserManagement = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("")
    const {userManagementCurrentPage}=useSelector((state)=>state.pagination)
    const {usersList,totalUsers}=useSelector((state)=>state.user)
    const postPerPage = 10;
    const handleSearch = (e) => {
      setSearchText(e.target.value)
      dispatch(getAllUsersList(1, postPerPage, e.target.value));
      dispatch(setuserManagementCurrentPage(1))
    }
    const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllUsersList(userManagementCurrentPage,postPerPage,searchText));
    //eslint-disable-next-line
  },[dispatch,userManagementCurrentPage])
  
  return (
      <Template>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            User List
          </p>
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
          <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
            <input
              className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
              placeholder="Enter user name"
              value={searchText}
              onChange={handleSearch}
            />
            <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between">
          {usersList?.length ? (
            <>{
            <table class="table-auto w-full">
              <thead>
                <tr>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Name
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Email
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                    Phone
                                      </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                   Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {usersList?.map((customer) => (
                  <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"   onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/user/${customer?.user_id}`)
                          }
                          }>
                    <td className="text-sm py-4 px-4">
                      <div className="flex justify-start items-center flex-grow gap-4">
                        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-10 h-10 overflow-hidden rounded-full bg-[#eaecf4]">
                        <img
                            className="h-full w-full rounded-lg object-cover"
                            src={customer?.image?customer?.image:'/profile.png'}
                            alt="preview"   effect="blur"
                            onError={imageOnError}
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start flex-grow relative">
                          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                            {customer?.agent_name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm p-4">{customer?.email} </td>
                    <td className="text-sm p-4 ">{customer?.phone}</td>
                    <td className="text-sm p-4  text-center">{customer?.creation_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>}
            </>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No User found
              </span>
            </div>
          )}
          <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={userManagementCurrentPage}
              totalCount={totalUsers}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setuserManagementCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
      </div>
    </Template>
  )
}

export default UserManagement