import { createSlice } from "@reduxjs/toolkit";

const enquireSlice=createSlice({
    name: "enquiry",
    initialState: {
        isLoading: false,
        enquiryList: [],
        totalEnquires:0
    },
    reducers: {
        getEnquiresListRequest: (state) => {
            state.isLoading = true;
        },
        getEnquiresListSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.enquiryList = payload?.enquiry_list;
            state.totalEnquires = payload?.totalCount;
        },
        getEnquiresListFail: (state) => {
            state.isLoading = false;
            state.enquiryList = []
            state.totalEnquires = 0
        },
        
    }
})

export default enquireSlice.reducer;
export const {getEnquiresListFail,getEnquiresListRequest,getEnquiresListSuccess } = enquireSlice.actions;