import React, { useEffect,Fragment } from "react";
import Template from "../../components/common/Template";
import {  PlusIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllAnimalList } from "../../services/api";
import { postPerPage } from "../../constants";
import Pagination from "../../components/common/pagination";
import { setAnimalManagementCurrentPageCurrentPage, setDeactivatedAnimalManagementCurrentPageCurrentPage } from "../../redux/slices/paginationSlice";
import DeleteAnimal from "./deleteanimal";
import { Tab } from "@headlessui/react";
import ActiveAnimal from "./activeanimal";
import { PencilIcon } from "@heroicons/react/24/outline";

const AnimalMangement = () => {
const {animalList,totalAnimals}=useSelector((state)=>state.animal)
const {animalManagementCurrentPage,deactivedAnimalManagementCurrentPage}=useSelector((state)=>state.pagination)
  const [searchValue, setsearchValue] = useState("")
  const [deactiveSearchValue, setdeactiveSearchValue] = useState("")
  const [selectedTab, setselectedTab] = useState(0)

  const dispatch = useDispatch();


  const handleSearch = (e,index) => {
    if(index===0){
      setsearchValue(e.target.value);
      dispatch(getAllAnimalList(1,postPerPage,e.target.value,1))
      dispatch(setAnimalManagementCurrentPageCurrentPage(1))
    } else {
      setdeactiveSearchValue(e.target.value);
      dispatch(getAllAnimalList(1,postPerPage,e.target.value,0))
      dispatch(setDeactivatedAnimalManagementCurrentPageCurrentPage(1))
    }
  }
  useEffect(() => {
    if (selectedTab === 0) { 
      dispatch(getAllAnimalList(animalManagementCurrentPage, postPerPage, searchValue,1))
    } else {
      dispatch(getAllAnimalList(deactivedAnimalManagementCurrentPage, postPerPage, deactiveSearchValue,0))
    }
    //eslint-disable-next-line
  },[animalManagementCurrentPage,selectedTab,deactivedAnimalManagementCurrentPage])

  return (
    <Template>
      {
      selectedTab===0 ?
        <div className="flex-row bg-slate-300 justify-between flex items-center">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Active  Animal List
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center flex-row">
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
              <input
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
                placeholder="Enter animal name"
                value={searchValue}
                onChange={(e)=>handleSearch(e,0)}
              />
              <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
                <SearchIcon />
              </div>
            </div>
          </div>

          <Link
            to={"/animal_list/add"}
            // className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 transition-all"
          >
            <div className=" flex gap-2 bg-[#6a9818] px-4 py-2 text-white rounded-3xl items-center justify-center">
              <PlusIcon className="h-5" />
              Add Animal
            </div>
          </Link>
        </div>
      </div>:<div className="flex-row bg-slate-300 justify-between flex items-center">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
          <div className="flex justify-start items-center flex-grow relative gap-[22px]">
            <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
              Inactive Animal List
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center flex-row">
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
            <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
              <input
                className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
                placeholder="Enter animal name"
                value={deactiveSearchValue}
                onChange={(e)=>handleSearch(e,1)}
              />
              <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <Tab.Group selectedIndex={selectedTab} onChange={setselectedTab}>
      <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-8 border border-black/[0.08]">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Active
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Inactive
                  </button>
                )}
              </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
        {
          animalList?.length ?
        <table class="table-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-black/10 text-xs font-semibold py-3 text-left px-4">
              Name
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Description
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Food
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Activities
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center w-0">
              Livespans
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left"></th>
          </tr>
        </thead>

        <tbody>
          {animalList?.map((animal) => (
            <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5" key={animal?.animal_id}>
            <td className="text-sm py-4 px-4">
              <div className="flex justify-start items-center flex-grow gap-4">
                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-10 h-10 overflow-hidden rounded-full bg-[#eaecf4]">
                  <img
                    src={animal?.thumb_image}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-col justify-start items-start flex-grow relative">
                  <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                 {animal?.name}
                  </p>
                </div>
              </div>
            </td>
            <td className="text-sm   p-4">
            {animal?.description}
            </td>
            <td className="text-sm p-4">    {animal?.food}</td>
            <td className="text-sm p-4">
            {animal?.activity}
            </td>
            <td className="text-sm p-4">
              <div className="flex justify-center">
                <div className="flex w-auto px-2 rounded-full bg-green-200 text-[11px] font-medium">
                {animal?.life_span ? `${animal?.life_span} yr`:animal?.life_span}
                </div>
              </div>
            </td>
            <td className="text-sm p-4">
              <div className="flex justify-end gap-2">
                <div className=" flex justify-end items-center gap-1">
                  <Link
                     to={`/animal_list/edit/${animal?.animal_id}`}
                    className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all"
                  >
                    <PencilIcon className="w-4 h-4" />
                  </Link>
                  <DeleteAnimal animal={animal} />
                </div>
              </div>
            </td>
          </tr>
          ))}
        </tbody>
          </table>:(
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Animals found
              </span>
            </div>
          )
      }
      <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={animalManagementCurrentPage}
              totalCount={totalAnimals}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setAnimalManagementCurrentPageCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
          </div>
          </Tab.Panel>
          <Tab.Panel>
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[90vh] justify-between">
        {
          animalList?.length ?
        <table class="table-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-black/10 text-xs font-semibold py-3 text-left px-4">
              Name
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Description
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Food
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
              Activities
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center w-0">
              Livespan
            </th>
            <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left"></th>
          </tr>
        </thead>

        <tbody>
          {animalList?.map((animal) => (
            <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5" key={animal?.animal_id}>
            <td className="text-sm py-4 px-4">
              <div className="flex justify-start items-center flex-grow gap-4">
                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-10 h-10 overflow-hidden rounded-full bg-[#eaecf4]">
                  <img
                    src={animal?.thumb_image}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-col justify-start items-start flex-grow relative">
                  <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                 {animal?.name}
                  </p>
                </div>
              </div>
            </td>
            <td className="text-sm   p-4">
            {animal?.description}
            </td>
            <td className="text-sm p-4">    {animal?.food}</td>
            <td className="text-sm p-4">
            {animal?.activity}
            </td>
            <td className="text-sm p-4">
              <div className="flex justify-center">
                <div className="flex w-auto px-2 rounded-full bg-green-200 text-[11px] font-medium">
                {animal?.life_span ? `${animal?.life_span} yr`:animal?.life_span}
                </div>
              </div>
            </td>
            <td className="text-sm p-4">
              <div className="flex justify-end gap-2">
                <div className=" flex justify-end items-center gap-1">
                  <ActiveAnimal animal={animal} />
                </div>
              </div>
            </td>
          </tr>
          ))}
        </tbody>
          </table>:(
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Animals found
              </span>
            </div>
          )
      }
      <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={deactivedAnimalManagementCurrentPage}
              totalCount={totalAnimals}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setDeactivatedAnimalManagementCurrentPageCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
          </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

        
    </Template>
  );
};

export default AnimalMangement;
