import { createSlice } from "@reduxjs/toolkit";

const agentSlice=createSlice({
    name: "agent",
    initialState:{
        isLoading: false,
        agentList: [],
        totalagent: 0,
        agentDetails:{}
    },
    reducers: {
        getAgentListRequest: (state) => {
            state.isLoading = true;
        },
        getAgentListSuccess: (state,{payload}) => {
            state.isLoading = false;
            state.agentList = payload?.agent_list
            state.totalagent=payload?.total_records
        },
        getAgentListFail: (state) => {
            state.isLoading = false;
            state.agentList =[]
            state.totalagent=0
        },
        setAgentDetails: (state,{payload}) => {
            state.agentDetails=state.agentList.find((agent)=>Number(agent?.agent_id)===Number(payload))
        },
        setAgentProfile: (state,{payload}) => {
            state.agentDetails.image=payload
        },

    }
})

export default agentSlice.reducer;
export const {getAgentListFail,getAgentListRequest,getAgentListSuccess,setAgentDetails,setAgentProfile} = agentSlice.actions;