import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { filterAnimalList, setAllAnimalList } from "../../redux/slices/animalSlice";
// import { filterequipmentTypes } from "../../../redux/slices/equipmentsSlice";
export default function MultiSelect({selectedData,allData,onSelect,id_key,value_key}) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");

    const handleChange = (value) => {
        const filteredArray = value.filter((item, index, self) => {
            // Create a unique key based on the "id" property
            const key = item[id_key];
            // Check if the key is already present in the object
            const isUnique = !self.find((obj, idx) => idx !== index && obj[id_key] === key);
            return isUnique;
        });
       onSelect(filteredArray)
    }
  return (
    <div className="flex z-1000">
      <div className="w-full">
        <Combobox value={selectedData} onChange={handleChange} multiple >
          <div className="relative mt-1">
              <Combobox.Button className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={(event) => {
                  setQuery(event.target.value)
                  dispatch(filterAnimalList(event.target.value))
                }}
                displayValue={(selectedData) =>selectedData?.length ? `${selectedData?.length} selected`:`Select Options`}
              />
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400 absolute inset-y-0 right-0 flex items-center pr-2"
                  aria-hidden="true"
                />
            </div>
              </Combobox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => {
                setQuery("")
                dispatch(setAllAnimalList())
              }}
            >
              <Combobox.Options className="absolute bottom-[100%] mt-1 z-[999] max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {allData?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  allData?.map((types) => (
                    <Combobox.Option
                      key={types[id_key]}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 mb-1 ${
                          selectedData?.find((type)=>type[id_key]===types[id_key]) ? "bg-teal-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={types}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selectedData?.find((type)=>type[id_key]===types[id_key])  ? "font-medium" : "font-normal"
                            }`}
                          >
                            {types[value_key]}
                          </span>
                          {selectedData?.find((type)=>type[id_key]===types[id_key]) ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-teal-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}
