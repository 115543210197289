import React, { useEffect, useState,Fragment } from 'react'
import Template from '../../components/common/Template'
// import Pagination from '../../components/common/pagination'
import { ReactComponent as SearchIcon } from "../../assets/icons/searchicon.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/addicon.svg";
import { imageOnError } from '../../components/utils/imageOnError';
import { useDispatch, useSelector } from 'react-redux';
import { setDeactivatedLocationManagementCurrentPage, setlocationManagementCurrentPageCurrentPage } from '../../redux/slices/paginationSlice';
import Pagination from '../../components/common/pagination';
import { Link, useNavigate } from 'react-router-dom';
import { getAllLocationList } from '../../services/api';
import { postPerPage } from '../../constants';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Tab } from '@headlessui/react';
import DeleteLocation from './deleteLocation';
import ActiveLocation from './activeLocation';

const UserManagement = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("")
    const [deactivatedSearchText, setdeactivatedSearchText] = useState("")
    const [currentTab, setcurrentTab] = useState(0)
    const {locationManagementCurrentPage,deactivatedLocationManagementCurrentPage}=useSelector((state)=>state.pagination)
        const {locationList,totalLocationList}=useSelector((state)=>state.location)
  const handleSearch = (e) => {
      if(currentTab===0){
        setSearchText(e.target.value)
        dispatch((getAllLocationList(1,postPerPage,e.target.value,1)))
        dispatch(setlocationManagementCurrentPageCurrentPage(1))
      } else {
        setdeactivatedSearchText(e.target.value)
        dispatch((getAllLocationList(1,postPerPage,e.target.value,0)))
        dispatch(setDeactivatedLocationManagementCurrentPage(1))
      }
    }

  const navigate = useNavigate();
  useEffect(() => {
    if (currentTab === 0) { 
      dispatch((getAllLocationList(locationManagementCurrentPage,postPerPage,searchText,1)))
    } else {
      dispatch((getAllLocationList(deactivatedLocationManagementCurrentPage,postPerPage,searchText,0)))
    }
    //eslint-disable-next-line
  },[locationManagementCurrentPage,dispatch,deactivatedLocationManagementCurrentPage,currentTab])
  return (
      <Template>
   {    currentTab===0 ?   <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Location List
          </p>
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
          <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
            <input
              className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
              placeholder="Enter  location name"
              value={searchText}
              onChange={handleSearch}
            />
            <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
              <SearchIcon />
            </div>
          </div>
          <Link
            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1 pl-4 pr-5 py-[10px] rounded-full bg-[#6a9818]"
            style={{ boxShadow: "0px 1px 0px 0 rgba(0,0,0,0.08)" }}
            to={"/location/add"}
          >
            <AddIcon />
            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-white">
              Add Location
            </p>
          </Link>
        </div>
      </div>:<div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 px-8 py-4">
        <div className="flex justify-start items-center flex-grow relative gap-[22px]">
          <p className="flex-grow-0 flex-shrink-0 text-2xl font-semibold text-left capitalize text-[#202223]">
            Inactive Location List
          </p>
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
          <div className="flex justify-start relative items-center flex-grow-0 flex-shrink-0 gap-3">
            <input
              className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-72 relative gap-2 px-4 py-2 rounded-full bg-white pl-9 placeholder:text-sm"
              placeholder="Enter  name"
              value={deactivatedSearchText}
              onChange={handleSearch}
            />
            <div className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#7b8794] absolute left-3">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>}
      <Tab.Group selectedIndex={currentTab} onChange={setcurrentTab}>
      <Tab.List className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-8 border border-black/[0.08]">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Active
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4 text-sm font-medium text-center focus:ring-0 outline-none transition-all ${
                      selected
                        ? "border-b-2 border-[#186ced] text-[#186ced]"
                        : "border-b-2 border-transparent text-gray-400 hover:text-gray-700"
                    } `}
                  >
                   Inactive
                  </button>
                )}
              </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between">
          {locationList?.length ? (
            <>{
            <table class="table-auto w-full">
              <thead>
                <tr>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Name
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Description
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                   Date
                    </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                    </th>  
                </tr>
              </thead>
              <tbody>
                {locationList?.map((location) => (
                  <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"   onClick={(e) => {
                        e.stopPropagation()
                        // navigate(`/user/${customer?.user_id}`)
                          }
                          }>
                    <td className="text-sm py-4 px-4">
                      <div className="flex justify-start items-center flex-grow gap-4">
                        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[72px] h-[72px] overflow-hidden gap-2.5 rounded-md bg-[#eaecf4]">
                        <img
                            className="h-full w-full rounded-lg object-cover"
                            src={location?.image?location?.image:'/profile.png'}
                            alt="preview"   effect="blur"
                            onError={imageOnError}
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start flex-grow relative">
                          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                            {location?.location_name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm p-4">{location?.description} </td>
                    <td className="text-sm  p-4">{location?.creation_date}</td>
                    <td className='text-sm  p-4'>
                    <div className="flex justify-end gap-2">
                <div className=" flex justify-end items-center gap-1">
                      <Link
                        to={`/location_edit/${location?.location_id}`}
                       className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all"
                      >
                    <PencilIcon className='h-4 w-4'  />
                          </Link>
                          <DeleteLocation location={location} value={searchText} />
                          </div>
                          </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>}
            </>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No location found
              </span>
            </div>
          )}
          <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={locationManagementCurrentPage}
              totalCount={totalLocationList}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setlocationManagementCurrentPageCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
      </div>
          </Tab.Panel>
          <Tab.Panel>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow overflow-hidden bg-white">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[70vh] justify-between">
          {locationList?.length ? (
            <>{
            <table class="table-auto w-full">
              <thead>
                <tr>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Name
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-left">
                   Description
                  </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                   Date
                    </th>
                  <th className="border-b border-black/10 text-xs font-semibold px-4 py-3 text-center">
                    </th>  
                </tr>
              </thead>
              <tbody>
                {locationList?.map((location) => (
                  <tr className="group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"   onClick={(e) => {
                        e.stopPropagation()
                          }
                          }>
                    <td className="text-sm py-4 px-4">
                      <div className="flex justify-start items-center flex-grow gap-4">
                        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[72px] h-[72px] overflow-hidden gap-2.5 rounded-md bg-[#eaecf4]">
                        <img
                            className="h-full w-full rounded-lg object-cover"
                            src={location?.image?location?.image:'/profile.png'}
                            alt="preview"   effect="blur"
                            onError={imageOnError}
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start flex-grow relative">
                          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#202223]">
                            {location?.location_name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm p-4">{location?.description} </td>
                    <td className="text-sm p-4 text-center">{location?.creation_date}</td>
                    <td className='text-sm  p-4'>
                <div className=" flex justify-end items-center gap-1">
                    <ActiveLocation location={location} value={deactivatedSearchText} />
                          </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>}
            </>
          ) : (
            <div className="flex justify-center items-center h-[70vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No location found
              </span>
            </div>
          )}
          <div className="flex justify-center w-full p-4 mb-4">
            <Pagination
              className="pagination-bar"
              currentPage={deactivatedLocationManagementCurrentPage}
              totalCount={totalLocationList}
              pageSize={postPerPage}
              onPageChange={(page) => {
                dispatch(setDeactivatedLocationManagementCurrentPage(page))
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                      if (currentScroll > 0) {
                        window.scrollTo(0, currentScroll - (currentScroll / 1));
                      }
              }}
            />
          </div>
        </div>
      </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Template>
  )
}

export default UserManagement