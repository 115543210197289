import React, { useState } from "react";
import {ReactComponent as RetriveIcon} from "../../assets/icons/retrive_icon.svg"
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import axiosConfig from "../../axios_config";
import {  getAllAnimalList } from "../../services/api";
const ActiveAnimal = ({animal}) => {

    const dispatch = useDispatch();

    const [dynamicCode, setdynamicCode] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [typedText, settypedText] = useState("");
    const [deleteLoading, setdeleteLoading] = useState(false);
  const closeModal = (e) => {
    e?.target?.classList?.forEach((classes) => {
      if (classes === 'Modal-Container') {
        setShowModal(false)
      }
    })
    }
    
    const openModal = () => {
        const randomNumber = Math.floor(Math.random() * 10000);
        setdynamicCode(randomNumber.toString().padStart(4, 0))
        settypedText("")
        setShowModal(true)
    }
const {deactivedAnimalManagementCurrentPage}=useSelector((state)=>state.pagination)
  
    const handleDelete = async() => {
        try {
            setdeleteLoading(true);
          const { data } = await axiosConfig.post(`/animal/de-active?animal_id=${animal?.animal_id}&status=1`);
          if (data?.data) {
              if (data?.data?.status === "success") {
                  toast.success(data?.data?.message);
                  setShowModal(false);
                   dispatch(getAllAnimalList(deactivedAnimalManagementCurrentPage,10,"",0));
                  setdeleteLoading(false);
              } else {
                  toast.error(data?.data?.message);
                  setdeleteLoading(false);
              }
          }
        } catch (error) {
          setdeleteLoading(false);
            toast.error(error?.response?.data?.message);
      }
  }
  
  return (
    <>
    <button className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative rounded gap-2.5 p-2 bg-white shadow-sm hover:bg-blue-400 transition-all" onClick={()=>openModal()}>
                         <RetriveIcon  />
                       </button>
   {showModal ? (
     <>
     <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-lg w-full Modal-Container bg-[#6a9818]/30 " onClick={closeModal}>
         <div className="relative w-auto mx-auto">
           <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none">
                           <div className="flex items-center justify-center gap-2 rounded-2xl flex-col py-4 w-[420px]">  
               <button className="text-xl font-semibold self-end mr-4" onClick={() => setShowModal(false)}>X</button>
               <div className="flex justify-center items-center w-full">  
                               <RetriveIcon />
                           </div>
                           <div className="flex flex-col justify-center items-center  py-3">
                                   <p className="text-2xl font-semibold w-full text-center">Are you sure want to active animal({animal?.name})</p>
                               </div>
                               <div className="flex flex-col w-full gap-2 px-2">
                                   <span className="w-full px-1 text-center font-light">To Confrim , Type { dynamicCode} in the Below Box</span>
                                   <input className="form-input w-[50%] m-auto  bg-transparent rounded-2xl  focus:border-black focus:bg-white focus:ring-0 placeholder:text-sm text-center" value={typedText} onChange={(e)=>settypedText(e.target.value)} type="number" />
                                   <div className="flex justify-center gap-5 w-full  ">
                                   <button className={`w-[35%] text-center px-3 py-2 rounded-2xl border-solid border border-gray-600 ${(deleteLoading)  ? `text-red-900 bg-gray-400 cursor-not-allowed` : `hover:bg-blue-600 hover:text-white cursor-pointer`} `} disabled={deleteLoading ? "disable" : ""} onClick={()=>setShowModal(false)}>Cancel</button>
                                   <button className={`w-[35%] text-center px-3 py-2 rounded-2xl border-solid border border-gray-600 ${(Number(typedText) !== Number(dynamicCode) || deleteLoading)  ? `text-red-900 bg-gray-400 cursor-not-allowed` : `hover:bg-red-900 hover:text-white cursor-pointer`} `} disabled={Number(typedText) !== Number(dynamicCode) || deleteLoading ? "disable" : ""} onClick={handleDelete}>Active</button>
                                   </div>
                               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   ) : null}
 </>
  );
};

export default ActiveAnimal;